import React from 'react';
import {Create, SimpleForm, TextInput, required, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin';

export default function NotificationTypeCreate(props: any) {
  return (
    <Create title="createCategory" {...props}>
      <SimpleForm>
        <TextInput source="type" validate={[required()]} />
        <TextInput source="label" validate={[required()]} />
          <ReferenceArrayInput
              reference={"citizens"}
              source={"citizen_ids"}
              filterToQuery={(search: any) => ({ name: search })}
          >
              <AutocompleteArrayInput
                  optionText={(citizen: any) => `${citizen?citizen.firstName + " " + citizen.lastName: ""}`}
                  optionValue="id"
                  fullWidth={true}
                  label={"citizens"}
              />
          </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}
