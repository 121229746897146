import { Link } from '@mui/material';
import React from 'react';
import {
  Datagrid,
  DeleteButton,
  List,
  TextField,
  FunctionField,
  Filter,
  SearchInput,
  EditButton,
  TopToolbar,
  ExportButton,
  CreateButton,
} from 'react-admin';
import ExcelJs from "exceljs";
import {saveAs} from "file-saver";

const NameFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder='Nom' source="name" resettable alwaysOn />
    <SearchInput placeholder='N° de téléphone' source="phone" resettable alwaysOn />
  </Filter>
);

const exporter = (citizens : any) => {
  const workbook = new ExcelJs.Workbook();
  workbook.addWorksheet('Citoyens');
  const worksheet = workbook.getWorksheet('Citoyens')

  const headers = ['Id', 'Nom', 'Prénom', 'E-mail', 'N° de téléphone', 'Commune', 'Village'];

  //Header
  let row = worksheet.getRow(1);
  let column = 1;
  headers.forEach(header => {
    row.getCell(column).value = header;
    column ++;
  });


  //Data
  let rowId = 2;
  citizens.forEach((citizen: any) => {
    const data = [citizen.id, citizen.lastName, citizen.firstName, citizen.email, citizen.phone, citizen.communeName, citizen.villageName];

    row = worksheet.getRow(rowId);
    let column = 1;
    data.forEach(col => {
      row.getCell(column).value = col;
      column ++;
    });
    rowId++;
  });

  worksheet.autoFilter = {
    from: {row: 1, column: 1},
    to: {row: rowId, column: headers.length}
  }

  worksheet.columns.forEach((column: any) => {
    if(column.values) {
      const lengths = column.values.map((v: any) => v?.toString().length);
      const maxLength =  Math.max(...lengths.filter((v: any) => typeof v === 'number')) + 2;
      column.width = maxLength;
    }
  });

  workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
    const blob = new Blob([buffer], { type: "application/xlsx"});
    saveAs(blob, 'citoyens.xlsx');
  })
}

const ListActions = (props: any) => (
  <TopToolbar>
    <CreateButton />
    <ExportButton exporter={exporter} maxResults={1000000}/>
  </TopToolbar>
);

export default function CitizenList(props: any) {
  return (
    <List {...props} actions={<ListActions />} filters={<NameFilter />} bulkActionButtons={false}>
      <Datagrid>
        <FunctionField
          render={(owner: any) => `${owner.firstName} ${owner.lastName}`}
          label="citizenName"
          sortBy={"Name"}
        />
        <TextField source="address" />
        <TextField source="phone" />
        <FunctionField
          label="citizenVillage"
          render={(citizen: any) => {
            return <Link href={`/villages/${citizen.villageId}`}>{citizen.villageName}</Link>;
          }}
        />
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
}
