import { Grid } from '@mui/material';
import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  required,
  NumberInput,
} from 'react-admin';

export default function ActorCreate(props: any) {
  const t = useTranslate();

  // @ts-ignore
  // @ts-ignore
  return (
    <Create title="createActor" {...props}>
      <SimpleForm>
        <Grid container style={{ width: '100%' }} spacing={2}>
          <Grid item xs={8} md={4}>
            <NumberInput variant={"filled"} source="noActor" label="resources.actors.fields.noActor" style={{width: '100%'}} validate={[required()]}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="firstName" label="resources.actors.fields.name" style={{width: '100%'}} validate={[required()]}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="filiation" label="resources.actors.fields.filiation" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="filiationAddress" label="resources.actors.fields.filiationAddress" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="address" label="resources.actors.fields.address" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="address2" label="resources.actors.fields.address2" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="locality" label="resources.actors.fields.locality" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="departement" label="resources.actors.fields.departement" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="codeCity" label="resources.actors.fields.codeCity" style={{width: '100%'}}/>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput variant={"filled"} source="land" label="resources.actors.fields.land" style={{width: '100%'}}/>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}
