import { Grid, Button, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import React, {useEffect, useState} from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useTranslate,
  ReferenceArrayInput,
  SelectArrayInput, AutocompleteArrayInput, required, SelectInput, usePermissions, useRecordContext, useEditContext, FormDataConsumer, TopToolbar,
  SaveButton,
  Toolbar,
  DeleteButton
} from 'react-admin';

import s from './Citizen.module.css';
import PhoneInput from "../common/PhoneInput";
import { checkBuildingManagement } from '../../raUtils/authProvider';
import { isDransgrid, isLiddes } from '../../raUtils/httpClient';
import { fetchCommunes, fetchVillages } from '../../api';
import dataProvider from '../../raUtils/dataProvider';

export default function CitizenEdit(props: any) {
  const t = useTranslate();
  const { permissions, isLoading } = usePermissions();

  const url = window.location.href.split("/");
  const id = url[url.length - 1];

  
  const [phoneDisabled, setPhoneDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState('');

  const [village, setVillage] = useState<any>('');
  const [villages, setVillages] = useState<any[]>([]);
  const [filteredVillages, setFilteredVillages] = useState<any[]>([{id: -1, name: "Sélectionner une commune", disabled: true}]);

  const [communes, setCommunes] = useState<any[]>([]);
  const [commune, setCommune] = useState<any>('');

  

  function phoneEnable(){
    setPhoneDisabled(false);
  }

  function emailEnable(){
    setEmailDisabled(false);
  }

  useEffect(() => {
    fetchVillages().then((response) => {
      const responseVillages = response.data;
      setVillages(responseVillages);

      if(isLiddes()){
        const liddesVillages = responseVillages.filter((village) => village.commune.name === "Liddes");
        setFilteredVillages(liddesVillages);
      }
      dataProvider.getOne('citizens', {id: id}).then((response) => {
        const citizen = response.data;
        const village = responseVillages.find((village) => village.id === citizen.village.id);
        setCommune(village.commune.id);
        setPhoneNumber(citizen.phone);

        const villagesFromCommune = responseVillages.filter((v) => v.commune.id === village.commune.id);
        //order by name
        villagesFromCommune.sort((a, b) => (a.name > b.name) ? 1 : -1);
        setFilteredVillages(villagesFromCommune);
        setVillage(village.id);
      })
    })

    fetchCommunes().then((response) => {
        const responseCommunes = response.data;
        setCommunes(responseCommunes);
        if(isLiddes()){
          const liddes = responseCommunes.find((commune) => commune.name === "Liddes");
          setCommune(liddes.id);
        }
    })
  }, []);

  function filterVillageByCommuneId(id){
    const villagesFromCommune = villages.filter((village) => village.commune.id === id);
    //order by name
    villagesFromCommune.sort((a, b) => (a.name > b.name) ? 1 : -1);
    setFilteredVillages(villagesFromCommune);
  }
  
  function handleChangeCommune(event: any) {
    setCommune(event.target.value);
    filterVillageByCommuneId(event.target.value);
  }

  function handleChangeVillage(event: any) {
    setVillage(event.target.value);
  }

  function handleChangePhoneNumber(event: any) {
    setPhoneNumber(event);
  }

  const transform = data => ({
      ...data,
      village: village,
      phone: "+" + phoneNumber.replaceAll("+", ""),
      notificationPreferenceIds: isDransgrid() ? [1, 2] : data.notificationPreferenceIds
  });
  
  const PostEditActions = () => (
    <Toolbar style={{display: "flex", justifyContent: "space-between"}}>
        <SaveButton alwaysEnable />
        <DeleteButton />
    </Toolbar>
);

  return (
    <Edit title="editCitizen" {...props} mutationMode="pessimistic" redirect={"false"} transform={transform} >
      <SimpleForm toolbar={<PostEditActions />}>
        <Grid container style={{ width: '100%' }} spacing={2}>
          <Grid item xs={8} md={4}>
            <TextInput source="id" label="citizenId" disabled style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            {
              emailDisabled ?
                  <Grid container style={{ width: '100%' }} spacing={0}>
                    <Grid item xs={8} md={10}>
                      <TextInput source="email" label="citizenEmail" disabled={true} style={{ width: '100%' }} />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <IconButton onClick={emailEnable} style={{width: '100%', height: '60px'}}> <LockOpenIcon /> </IconButton>
                    </Grid>
                  </Grid>
                  :
                  <TextInput source="email" label="citizenEmail" style={{ width: '100%' }} />
            }
          </Grid>
          <Grid item xs={8} md={4}>
              {
                phoneDisabled ?
                    <Grid container style={{ width: '100%' }} spacing={0}>
                      <Grid item xs={8} md={10}>
                        <PhoneInput value={phoneNumber} label={t("citizenPhone")} validate={required()} disabled={true} required style={{ width: '100%' }} />
                      </Grid>
                      <Grid item xs={8} md={2}>
                        <IconButton onClick={phoneEnable} style={{width: '100%', height: '100%'}}> <LockOpenIcon /> </IconButton>
                      </Grid>
                    </Grid>
                  :
                <PhoneInput onChange={handleChangePhoneNumber} value={phoneNumber} label={t("citizenPhone")} validate={required()} required style={{ width: '100%' }} />
              }
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="firstName" label="citizenFirstName" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="lastName" label="citizenLastName" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="address" label="citizenAddress" style={{ width: '100%' }} />
          </Grid>

          {
            isLiddes() ? <></> :
            <Grid item xs={8} md={4}>
              <FormControl fullWidth required>
                  <InputLabel id="label-commune">Commune</InputLabel>
                  <Select sx={{InputLabelRoot:"#d32f2f"}} id="commune" name="commune" labelId="commune" value={commune} style={{width: '100%'}} onChange={handleChangeCommune}>
                      {
                          communes.map((commune) => {
                              return (<MenuItem value={commune.id} key={commune.id}>{commune.name}</MenuItem>)
                          })
                      }
                  </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={8} md={4}>
            <FormControl fullWidth required>
              <InputLabel id="label-village">Village</InputLabel>
              <Select labelId="village" value={village} style={{width: '100%'}} onChange={handleChangeVillage} label="Village">
                  {
                      filteredVillages.map((village) => {
                          return (<MenuItem value={village.id} key={village.id} disabled={village.disabled}>{village.name}</MenuItem>)
                      })
                  }
              </Select>
            </FormControl>
          </Grid>
          {
            isDransgrid() ? <></> :
            <Grid item xs={8} md={4}>
              <ReferenceArrayInput
                reference="notification_types"
                source="notificationPreferenceIds"
                classes={{ root: s.fullWidth }}
              >
                <AutocompleteArrayInput
                    optionText={(type: any) => type && type.label}
                    optionValue="id"
                    label="citizenNotificationPreferences"
                />
              </ReferenceArrayInput>
            </Grid>
          }

          {isLoading || !checkBuildingManagement(permissions==undefined?[]:permissions) ? <></> :
            <Grid item xs={8} md={4}>
              <ReferenceInput
                  reference="actors"
                  source="actorId"
                  resettable
              >
                <AutocompleteInput
                    source="owner"
                    optionText={(owner: any) => {
                      if(owner?.id != null){ return owner? owner.firstName + " " + owner.lastName + " " + owner.noActor : "" ;}
                      else{return "";}
                    }}
                    optionValue="id"
                    label={"actor"}
                    filterToQuery={(search) => ({ name: search })}
                />
              </ReferenceInput>
            </Grid>
          }
          
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
