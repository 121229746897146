import {Link, Snackbar, Box, Button, Typography, Grid} from '@mui/material';
import React from 'react';
import {
    Datagrid,
    CreateButton,
    DeleteButton,
    EditButton,
    List,
    TextField,
    FunctionField,
    Filter,
    SearchInput,
    useTranslate,
    TopToolbar,
    useListContext,
    CheckboxGroupInput,
} from 'react-admin';
import CheckboxInput from '../common/CheckboxInput';

import ImportButton from "../customButton/ImportButton"



const NameFilter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="name" resettable alwaysOn />
            <CheckboxInput label={"Afficher uniquement les acteurs avec propriété"} source={"actorWithoutBuilding"} resettable alwaysOn />
        </Filter>
    );
}


const ListActions = (props: any) => {
    const {
        className,
        basePath,
    } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton />
            <ImportButton url={"/actors/importActors"}/>
        </TopToolbar>
    );
};

export default function ActorList(props: any) {
  const t = useTranslate();

    return (
      <div>
        <List filters={<NameFilter />} actions={<ListActions/>}  empty={false} exporter={false} bulkActionButtons={false}>
          <Datagrid>
            <TextField source="firstName" label={t("resources.actors.fields.name")} />
            <TextField source="address" />
            <TextField source="locality" label="locality" sortBy="locality"/>
            <TextField source="nbBuildings" label="totalBuildings"/>
              <FunctionField
                  render={(actor: any) => {
                      const query = new URLSearchParams({ filter: JSON.stringify({ actorId: actor.id }) });

                      return <Link href={`/buildings?${query.toString()}`}>{t('showBuildings')}</Link>;
                  }}
              />
            <EditButton />
            <DeleteButton mutationMode="pessimistic"/>
          </Datagrid>
        </List>
      </div>
  );
}
