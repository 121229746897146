import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  Filter,
  SearchInput,
} from 'react-admin';

const NameFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="q" resettable alwaysOn />
  </Filter>
);

export default function LocalityList(props: any) {
  return (
    <List {...props} filters={<NameFilter />} exporter={false} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="code" />
        <TextField source="locality" />
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
}
