import React, { HtmlHTMLAttributes, createElement, ReactNode, useRef, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { defaultTheme, Notification, TitleComponent } from 'react-admin';

import { Card, Avatar, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationForm from './ConfirmationForm';
import theme from '../../theme';
import { isDransgrid } from '../../raUtils/httpClient';

export interface ConfirmationProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: object;
  className?: string;
  theme?: object;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
      maxWidth: '80%',
    },
    avatar: {
      margin: '0em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      // @ts-ignore
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' }
);

const backgroundImage = 'signup-background.jpg';

const SMSConfirmationPage: React.FunctionComponent<ConfirmationProps> = (props) => {
  const { title, classes: classesOverride, className, children, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  let backgroundImageLoaded = false;
  const classes = useStyles(props);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  function dransgrid(){
    return isDransgrid() ? true : false;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
              {dransgrid() ? 
                <div style={{ margin: 'auto', marginTop: '1em', width: 400, textAlign: 'center' }}>
                  <img
                    src="dransgrid-logo-black.png"
                    style={{ maxWidth: '80%' }}
                    alt="Commune de Liddes"
                  />
                </div>
                :
                <Typography style={{margin:'1em'}} variant="h4" align="center" gutterBottom>
                  Inscription aux notifications
                </Typography>
              }
          </div>
          <ConfirmationForm />
        </Card>
        {createElement(Notification)}
      </div>
    </ThemeProvider>
  );
};

// @ts-ignore
export default <SMSConfirmationPage />;
