import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {Snackbar, ClickAwayListener} from "@mui/material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTranslate } from "react-admin";

import API from "../../api"

const ImportButton = (props: any) => {
    const t = useTranslate();
    const translationMap = {
        import: t('customButton.importButton.button'),
        insertion: t("customButton.importButton.insertion"),
        update: t("customButton.importButton.update"),
        error: t("customButton.importButton.error"),
    }
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    function importCsv() {
        const input = document.getElementById("importFile") as HTMLInputElement;
        const files = input.files;

        let data: any[] = [];
        let reader = new FileReader();
        reader.onload = function () {
            let result = reader.result as string;
            let rows = result.split("\r\n");
            let nbRowsCheck = 0;
            rows.forEach(row => {
                let element = row.split(";");
                if(element.length > 1) data.push(element);
                nbRowsCheck ++;
                if(nbRowsCheck % 100 == 0){
                    setMessage(nbRowsCheck + " / " + rows.length + " lignes traitées");
                    setOpen(true);
                }
            });

            setMessage("En attente du serveur ...");
            setOpen(true);

            API.post(props.url, {data})
                .then(res => {
                    let response = res.data.data;
                    setMessage(response.insert + " " + translationMap.insertion + " " + response.update + " " + translationMap.update);
                    setOpen(true);
                    input.value = '';
                })
                .catch(err => {
                    setMessage(translationMap.error + " " + err);
                    setOpen(true);
                    input.value = '';
                })
        }

        if (files) {
            reader.readAsBinaryString(files[0]);
        }
    }

    return (
        <div>
            <Button
                color="primary"
                size="small"
                component="label"
                startIcon={<ImportExportIcon />}
                variant={props.variant}
            >
                {translationMap.import}
                <input
                    type="file"
                    id="importFile"
                    hidden
                    onChange={() => { importCsv(); }}
                />
            </Button>
            <ClickAwayListener onClickAway={() => { setOpen(false); }}>
                <Snackbar
                    open={open}
                    message={message}
                />
            </ClickAwayListener>
        </div>
    )
}

export default ImportButton;