import {AuthProvider, UserIdentity} from 'react-admin';
import api from '../api';

export function checkSuperuser(permissions: string[]): boolean {
  return permissions.includes('superuser');
}

export function checkGuest(permissions: string[]): boolean {
  return permissions.includes('guest');
}

export function checkBuildingManagement(permissions: string[]): boolean {  
  return permissions.includes('buildingManagement');
}

const provider: AuthProvider = {
  login: async ({ username, password }) => {
    const response = await api.post('/auth/login', { email: username, password });
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.error);
    }

    localStorage.setItem(
      'permissions',
      JSON.stringify(response.data.roles.map((role: { id: number; name: string }) => role.name))
    );

    localStorage.setItem(
        'userId',
        JSON.stringify(response.data.id)
    );


    window.location.href = '/';
    return Promise.resolve();
  },
  signup: async ({
    firstName,
    lastName,
    address,
    email,
    phone,
    village,
    notificationPreferences,
    clientCode,
  }: any) => {
    try {
      const response = await api.post('/auth/signup', {
        firstName,
        lastName,
        address,
        email,
        phone,
        village,
        notificationPreferences,
        clientCode,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error: any) {
      const message = error.response.data.error.message;
      error.message = message;

      throw error;
    }
  },
  confirm: async ({ code, token, firstName, lastName, address, email, village, notificationPreferences }: any) => {
    const response = await api.post('/auth/confirm', {
      code,
      token,
      firstName,
      lastName,
      address,
      email,
      village,
      notificationPreferences,
    });
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.error);
    }

    return response.data;
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const path = window.location.pathname;
    if (path === '/signup' || path === '/confirm') {
      return Promise.resolve();
    }

    await api.get('/auth/check');
    return Promise.resolve();
  },
  logout: async () => {
    await api.post('/auth/logout');
    return Promise.resolve();
  },
  getPermissions: () => {
    const path = window.location.pathname;
    if (path === '/signup' || path === '/confirm') {
      return Promise.resolve(['guest']);
    }

    const permissions = localStorage.getItem('permissions');
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  getIdentity: () => {
    let userId = localStorage.getItem("userId");
    if(userId == null) userId = "";
    const user: UserIdentity = {id: userId};
    return Promise.resolve(user);
  }
};

export default provider;
