import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';

import enTranslations from '../translations/en.json';
import frTranslations from '../translations/fr.json';

const defaultLng = process.env.NODE_ENV === 'development' ? 'en' : 'fr';

const i18nProvider = polyglotI18nProvider((locale) => {
  switch (locale) {
    case 'en':
      return { ...englishMessages, ...enTranslations };
    case 'fr':
    default:
      return { ...frenchMessages, ...frTranslations };
  }
}, localStorage.getItem('language') || defaultLng);

export default i18nProvider;
