import axios from 'axios';
import {Identifier} from "react-admin";
import { isDransgrid } from '../raUtils/httpClient';

export const API_URL = `/api`;

const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const fetchVillages = async () => {
  return API.get('/villages/all').then((response) => response.data);
};

export const fetchVillagesByClient = async () => {
  return API.get('/villages').then((response) => response.data);
}

export const fetchCommunes = async () => {
  return API.get('/communes/getAll').then((response) => response.data);
};

export const fetchCommunesByClient = async () => {
  return API.get('/communes').then((response) => response.data);
}

export const fetchNotificationTypes = async () => {
  return API.get('/notification_types').then((response) => response.data);
};

export const fetchCitizens = (category: number | null, commune: number | null, villages: any[]) => {
  const query = new URLSearchParams({ filter: JSON.stringify({ category, commune, villages }) });
  
  return API.get(`/citizens?${query.toString()}`).then((response) => response.data);
};

export const getClientCodeByUrl = () => {
  if (isDransgrid()) {
    return 'dransgrid';
  } else {
    return 'liddes';
  }
}

export const sendNotifications = ({
  receivers,
  message,
}: {
  receivers: any[];
  message: string;
}) => {
  return API.post('/notify', { message, receivers });
};

export const newVerificationCode = (token: string) => {
  const clientCode = getClientCodeByUrl();
  return API.post('/auth/newCode', { token, clientCode });
};

export default API;
