import { useCallback, FunctionComponent, useEffect, useState } from 'react';
import { useTranslate, useAuthProvider, useNotify, useSafeSetState } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Button, CardActions, CircularProgress, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router';
import {newVerificationCode} from "../../api";

interface Props {
  redirectTo?: string;
}

interface FormData {
  code: string;
}

const useConfirmSMS = () => {
  const authProvider = useAuthProvider();

  const confirmSMS = useCallback(
    (params: any = {}) =>
      authProvider.confirm(params).then((ret: any) => {
        return ret;
      }),
    [authProvider]
  );

  return confirmSMS;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  // @ts-ignore
  meta: { touched, error },
  // @ts-ignore
  input: inputProps,
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

let countRender = 0;
let time = 30;

const SignupForm: FunctionComponent<Props> = (props) => {
  countRender++;
  const [loading, setLoading] = useSafeSetState(false);
  const [isSuccess, setSuccess] = useSafeSetState(false);
  const t = useTranslate();
  const [newCodeText, setNewCodeText] = useSafeSetState(t('signup.newCode') + " (" + time + "s)");
  const [newCodeDisable, setNewCodeDisable] = useSafeSetState(true);
  const confirmSMS = useConfirmSMS();
  const history = useNavigate();
  const location = useLocation();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: any = {
      code: undefined,
    };

    if (!values.code) {
      errors.code = t('ra.validation.required');
    }

    return errors;
  };


  const changeNewCodeText = () => {
      time--;
      if (time <= 0) {
          setNewCodeDisable(false);
          setNewCodeText(t('signup.newCode'))
      } else {
          setNewCodeDisable(true);
          setNewCodeText(t('signup.newCode') + " (" + time + "s)")
          setTimeout(changeNewCodeText, 1000)
      }
  }
  if(countRender == 3 ) setTimeout(changeNewCodeText, 1000)

  const newCode = () => {
      time = 31;
      changeNewCodeText();
      //const token = new URLSearchParams(history.location.search).get('token');
      const token = new URLSearchParams(location.search).get('token');
      if(token) newVerificationCode(token);
  }

  const submit = (values: any) => {
    setLoading(true);

      //const token = new URLSearchParams(history.location.search).get('token');
      const token = new URLSearchParams(location.search).get('token');
      const forms = {
        firstName: new URLSearchParams(location.search).get('firstName'),
        lastName: new URLSearchParams(location.search).get('lastName'),
        email: new URLSearchParams(location.search).get('email'),
        address: new URLSearchParams(location.search).get('address'),
        village: new URLSearchParams(location.search).get('village'),
        notificationPreferences: new URLSearchParams(location.search).get('notificationPreferences'),
      }

    // @ts-ignore
    confirmSMS({ ...values, token, ...forms })
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((error: any) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
            {type: 'warning'},
        );
      });
  };

  if (isSuccess) {
    return <h4 style={{ textAlign: 'center' }}>{t('You have successfully registered!')}</h4>;
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="code"
                name="code"
                component={Input}
                label={t('signup.verificationCode')}
                disabled={loading}
              />
            </div>
          </div>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={newCodeDisable}
                    className={classes.button}
                    onClick={newCode}
                >
                    {newCodeText}
                </Button>
            </CardActions>
          <CardActions>
              <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
              >
                  {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                  {t('signup.verificationSubmit')}
              </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

export default SignupForm;
