import {Button, FormControl, InputLabel, MenuItem, Select, TextField, IconButton, Paper, Grid, Alert, Snackbar, Chip} from '@mui/material';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNotify, useTranslate } from 'react-admin';
import API, {fetchCitizens, fetchNotificationTypes, sendNotifications, fetchVillagesByClient, fetchCommunesByClient} from '../../api';

import s from './Mailing.module.css';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { isDransgrid } from '../../raUtils/httpClient';
import { formatPhone } from '../../raUtils/format';
import { SMSCost, SMSLeft, costPerSMS, maxSMS, messageMaxLength, unicodeMessageMaxLength } from '../../raUtils/SMSConst';

function MailingPage() {
  const t = useTranslate();
  const notify = useNotify();

  const [categories, setCategories] = useState<any[]>([]);
  const [villages, setVillages] = useState<any[]>([]);
  const [filteredVillages, setFilteredVillages] = useState<any[]>([{id: -1, name: "Tous les villages"}]);
  const [communes, setCommunes] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(-1);
  const [selectedVillage, setSelectedVillage] = useState<any[]>([-1]);
  const [selectedCommune, setSelectedCommune] = useState<number | null>(-1);
  const [receivers, setReceivers] = useState<any[]>([]);
  const [notifyMessage, setNotifyMessage] = useState('');
  const [hasUnicode, setHasUnicode] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [annualCount, setAnnualCount] = useState(0);

  function fetchReceivers(category, commune, villages) {
    fetchCitizens(category, commune, villages).then((response) => {
      const orderedReceivers = response.data.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1);
      setReceivers(orderedReceivers);
    });
  }

  const filterVillageByCommuneId = useCallback((id: any) => {
    const villagesFromCommune = villages.filter((village) => village.commune.id === id);
    //order by name
    villagesFromCommune.sort((a, b) => (a.name > b.name) ? 1 : -1);
    setFilteredVillages([{id: -1, name: "Tous les villages"}, ...villagesFromCommune]);
  }, [villages, filteredVillages]);

  useEffect(() => {

    API.get(`/sessionSMS/anualCount`).then((response) => {
      setAnnualCount(response.data.data.count);
    });

    fetchNotificationTypes().then((response) => {
      setCategories([{id: -1, label: "Toutes les catégories"}, ...response.data]);
    });

    fetchVillagesByClient().then((response) => {
      let villages = response.data;
      setVillages(villages);
      setFilteredVillages([{id: -1, name: "Tous les villages"}, ...villages]);
    });

    fetchCommunesByClient().then((response) => {
      let communes = response.data;
      if(communes.length == 1){
        setSelectedCommune(communes[0].id);
      } else {
        communes = [{id: -1, name: "Toutes les communes"}, ...communes];
      }
      setCommunes(communes);
    });

    fetchReceivers(null, null, []);
  }, []);

  const handleCategoryChange = useCallback((e) => {
    let category = e.target.value;
    setSelectedCategory(category);

    const villages = (selectedVillage.length<=0)?[]:selectedVillage;
    const commune = (selectedCommune==-1)?null:selectedCommune;

    category = (category == -1) ? null : category;

    fetchReceivers(category, commune, villages);

  }, [selectedVillage, selectedCommune]);

  const handleVillageChange = useCallback((e) => {
    let village = e.target.value;
    if(village.includes(-1)){
      if(selectedVillage.includes(-1)){
        village = village.filter((v) => v != -1);
      } else {
        village = [-1];
      }
    }

    setSelectedVillage(village);

    const category = (selectedCategory==-1)?null:selectedCategory;
    const commune = (selectedCommune==-1)?null:selectedCommune;

    village = (village.includes(-1)) ? [] : village;

    fetchReceivers(category, commune, village);

  }, [selectedVillage, selectedCategory, selectedCommune]);

  const handleCommuneChange = useCallback((e) => {
    let commune = e.target.value;
    setSelectedCommune(commune);

    const category = (selectedCategory==-1)?null:selectedCategory;
    let village = (selectedVillage.length <= 0 || commune==-1)?[]:selectedVillage;

    commune = (commune == -1) ? null : commune;

    // Si on a sélectionné tous les villages, on enlève le filtre sur les villages
    village = (village.includes(-1)) ? [] : village;

    if (commune == null) {
      // Si on a sélectionné toutes les communes, on sélectionne tous les villages
      setSelectedVillage([]);
    } else {
      // Sinon on filtre les villages pour la commune sélectionnée
      filterVillageByCommuneId(commune);
    }

    fetchReceivers(category, commune, village);

  }, [selectedCategory, selectedVillage, filterVillageByCommuneId]);

  const submitNotification = useCallback(() => {
    if (receivers.length == 0) {
      return notify('noReceivers', {type: 'warning'});
    }

    if (notifyMessage == '') {
      return notify('noMessage', {type: 'warning'});
    }

    setSendingSMS(true);

    sendNotifications({ receivers: receivers, message: notifyMessage })
        .then((response) => {
          setSendingSMS(false);
          if (response.status === 200) {
            notify('notificationSuccess', {type: 'success'});
          }
        })
        .catch(() => {
          setSendingSMS(false);
          notify('notificationFailure', {type: 'error'});
        });
  }, [selectedCategory, receivers, notifyMessage]);

  const removeReceiver = useCallback((id) => {
    let newReceivers = []
    for(let i = 0; i < receivers.length; i++){
      if(receivers[i].id != id) { // @ts-ignore
        newReceivers.push(receivers[i]);
      }
    }
    setReceivers(newReceivers);
  }, [receivers]);

  const columns = [
    { 
      field: 'delete', headerName: '', sortable: false, flex: 1, 
      renderCell: (params) => (
        <IconButton onClick={() => removeReceiver(params.row.id)} color='error'>
          <RemoveCircleIcon />
        </IconButton>
      )
    },
    { 
      field: 'fullName', sortable: false, flex: 10,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Nom et prénom '}
        </strong>
      ),
        
    },
    { 
      field: 'phone', sortable: false, flex: 10,
      valueGetter: (params) => `${formatPhone(params.row.phone) || ''}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'N° de téléphone'}
        </strong>
      ),
    },
  ]

  const emptyOverlay = () => {
    return (
      <div style={{ textAlign: 'center', paddingTop: '10px'}}>Aucun citoyen sélectionné</div>
    )
  }

  async function checkUnicode(message: string) {
    let unicode = false;
    for (let i = 0; i < message.length; i++) {
      if (message.charCodeAt(i) > 127) {
        unicode = true;
        break;
      }
    }
    setHasUnicode(unicode);
  }

  function handleMessageChange(e: any) {
    setNotifyMessage(e.target.value);
    checkUnicode(e.target.value);
  }

  function getMessageMaxLength() {
    return hasUnicode ? unicodeMessageMaxLength : messageMaxLength;
  }

  function getNbSMS() {
    const percentageUsed = hasUnicode ? (notifyMessage.length / unicodeMessageMaxLength) : (notifyMessage.length / messageMaxLength);
    return Math.ceil(percentageUsed * 6);
  }

  function getCostAmount(){
    return getNbSMS() * costPerSMS;
  }

  function getCost(){
    return getCostAmount() + ' CHF';
  }

  function getTotalCost(){
    return receivers.length * getCostAmount() + ' CHF';
  }

  return (
    <div>
      <Paper variant="outlined" style={{ padding: '10px 20px', marginTop: '20px' }}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={4}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>
              <div>
                { isDransgrid() ? <></> :
                  <FormControl className={s.formContainer}>
                    <InputLabel id="category">{t('category')}</InputLabel>
                    <Select
                        labelId="category"
                        value={selectedCategory ?? ''}
                        onChange={handleCategoryChange}
                    >
                      {categories.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.label}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>            
                }
                {
                  communes.length == 1 ? <></> : <>
                    <br />
                    <FormControl className={s.formContainer}>
                      <InputLabel id="commune">{t('commune')}</InputLabel>
                      <Select
                          labelId="commune"
                          value={selectedCommune ?? ''}
                          onChange={handleCommuneChange}
                      >
                        {communes.map((commune) => (
                            <MenuItem key={commune.id} value={commune.id}>
                              {commune.name}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl> 
                  </>
                }
                {
                  selectedCommune == -1 ? <></> :
                  <>
                    <br />
                    <FormControl className={s.formContainer}>
                      <InputLabel id="village">{t('village')}</InputLabel>
                      <Select
                          labelId="village"
                          value={selectedVillage ?? ''}
                          multiple
                          onChange={handleVillageChange}
                      >
                        {filteredVillages.map((village) => (
                            <MenuItem key={village.id} value={village.id}>
                              {village.name}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                }
                <div>
                  <TextField
                      style={{ width: '100%'}}
                      value={notifyMessage}
                      onChange={handleMessageChange}
                      placeholder={t('notifyPlaceholder')}
                      className={s.notifyInput}
                      variant="outlined"
                      multiline
                  />
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '14px', fontWeight: '400', textAlign: 'right', marginTop: '0px', color: (notifyMessage.length > getMessageMaxLength()? 'red': 'inherit') }}>
                      SMS: {getNbSMS()} / {maxSMS}
                    </p>
                    <p style={{fontSize: '14px', fontWeight: '400', textAlign: 'right', marginTop: '0px', color: (notifyMessage.length > getMessageMaxLength()? 'red': 'inherit') }}>
                      {hasUnicode ? 'Unicode ' : ''}Caractères: {notifyMessage.length} / {getMessageMaxLength()}
                    </p>
                    <p style={{fontSize: '14px', fontWeight: '400', textAlign: 'right', marginTop: '0px', color: (notifyMessage.length > getMessageMaxLength()? 'red': 'inherit') }}>
                      Coût: {getCost()}
                    </p>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '14px', fontWeight: '400', textAlign: 'right', marginTop: '0px', color: (notifyMessage.length > getMessageMaxLength()? 'red': 'inherit') }}>
                      SMS total: {getNbSMS() * receivers.length}
                    </p>
                    <p style={{fontSize: '14px', fontWeight: '400', textAlign: 'right', marginTop: '0px', color: (notifyMessage.length > getMessageMaxLength()? 'red': 'inherit') }}>
                      Coût total: {getTotalCost()}
                    </p>
                  </div>

                  <Button
                      onClick={submitNotification}
                      variant="contained"
                      color="primary"
                      className={s.sendButton}
                  >
                    {t('notificationSendButton')}
                  </Button>              
                </div>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                <Chip label={SMSLeft(annualCount) + " SMS gratuits restant"} color="success"/>
                <Chip label={SMSCost(annualCount) + " CHF de coût suplémentaire"} color="warning"/>
              </div>  
            </div>
          </Grid>
          <Grid item xs={12} md={8} style={{minHeight: '300px'}}>
            <DataGrid
              style={{ height: '100%', minHeight: '300px'}}
              rows={receivers}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
              slots={{
                noRowsOverlay: emptyOverlay,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={sendingSMS}
        message={"Envoi des SMS en cours, cela peut prendre quelques minutes ..."}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
}

// @ts-ignore
export default <MailingPage />;
