import React, {ReactNode} from 'react';
import FormGroup from '@mui/material/FormGroup';
import {
    Button,
    Select,
    MenuItem,
    Grid,
    IconButton, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useInput
} from 'react-admin'
import API from "../../api";
import {SelectChangeEvent} from "@mui/material/Select";

const DocumentAFournirInput = props => {

    const { field } = useInput(props);

    const { documentTypes } = props;

    const state = {
        id: field.value == undefined ? -1 : field.value.id,
        type: field.value == undefined ? 0 : field.value.type,
        file: field.value == undefined ? null : field.value.file,
    }

    const changeSelect = (event: SelectChangeEvent<any>, child: ReactNode) => {
        state.type = event.target.value;
        field.onChange(state);
    };

    const delFile = () =>{
        state.file = null;
        field.onChange(state);
    }

    const downloadFile = () => {
        const idDocument = field.value.id;
        API.get('/documents/' + idDocument)
            .then(res => {
                var a = document.createElement("a");
                a.href = res.data.data.content;
                a.download = res.data.data.name;
                a.click();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const changeFile = (event: React.ChangeEvent) => {
        const input = event.target as HTMLInputElement;
        if(input.files == null || input.files.length <= 0) return;
        const file = input.files[0];
        if(file == null) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            state.file = {
                name: file.name,
                content: reader.result
            }
            field.onChange(state);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const { type, file } = state
        return (
            <FormControl style={{width: "100%", paddingTop: "10px", paddingBottom: "10px"}}>
                <Grid container spacing={4}>
                    <Grid item xs={8}>
                        <Select
                            style={{width: "100%"}}
                            value={type}
                            onChange = {changeSelect}
                        >
                            <MenuItem key={0} value={0}>
                                -
                            </MenuItem>
                            {documentTypes?.map((option:any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label ?? option.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                    {(file == null) ?
                        <Grid item xs={2}>
                            <Button
                                style={{width: "100%"}}
                                variant="outlined"
                                component={"label"}
                            >
                                ...
                                <input
                                    type="file"
                                    value={""}
                                    onChange = {changeFile}
                                    hidden
                                />
                            </Button>
                        </Grid>
                        :
                        <Grid item xs={4}>
                            <Grid container spacing={0}>
                                <Grid item xs={9}>
                                    <Button style={{width: "100%"}} onClick={downloadFile}>
                                        {file.name.length < 20 ? file.name : file.name.substring(0, 17) + "..."}
                                    </Button>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button onClick={delFile}>
                                        <DeleteIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </FormControl>
        );
}

export default DocumentAFournirInput;
