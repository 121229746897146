import React from 'react';
import {Edit, SimpleForm, TextInput, required, AutocompleteArrayInput, ReferenceArrayInput} from 'react-admin';

export default function NotificationTypeEdit(props: any) {
  return (
    <Edit title="editCategory" mutationMode="pessimistic" redirect={"false"}>
      <SimpleForm>
        <TextInput source="type" validate={[required()]}/>
        <TextInput source="label" validate={[required()]}/>
        <ReferenceArrayInput
          reference={"citizens"}
          source={"citizen_ids"}
          filterToQuery={(search: any) => ({ name: search })}
        >
          <AutocompleteArrayInput
              optionText={(citizen: any) => `${citizen?citizen.firstName + " " + citizen.lastName: ""}`}
              optionValue="id"
              fullWidth={true}
              label={"citizens"}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}
