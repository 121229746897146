import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, {useEffect} from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  useTranslate,
  ReferenceInput,
  AutocompleteArrayInput, SelectArrayInput, FormDataConsumer, NumberInput, ReferenceArrayInput, DateInput,
  required,
} from 'react-admin';
import CheckboxInput from '../common/CheckboxInput';
import DocumentAFournirInput from "../common/DocumentAFournirInput";
import API from "../../api";
import TextArrayInput from "../common/TextArrayInput";

export default function BuildingEdit(props: any) {
  const t = useTranslate();

  const floorTypeChoices = {
    basement: t('basement'),
    first_floor: t('firstFloor'),
    ground_floor: t('groundFloor'),
    second_floor: t('secondFloor'),
    reserve_floor: t('reserveFloor'),
  };

  const [source, setSource] = React.useState("actors");
  const [documentTypes, setDocumentTypes] = React.useState([]);

  useEffect(() => {
    API.get('/documentTypes')
        .then(res => {
          const documents = res.data.data.map((document: { name: string; id: number; }) => {
            return {label: document.name, value: document.id}
          })
          setDocumentTypes(documents);
        })
        .catch(err => {
          console.log(err);
        })
  }, []);

  const onTypeChange = (event: any, values: any) => {
    const tempSource = event.target.value == 1 ? "building_owners" : "actors";
    setSource(tempSource);
  }

  return (
    <Create title="createBuilding" {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container style={{ width: '100%' }} spacing={2}>
          <Grid item xs={8} md={4}>
            <ReferenceInput reference={"ownerTypes"} source={"ownerType_id"}  onChange={(e) => onTypeChange(e, null)}>
              <SelectInput
                  fullWidth={true}
                  defaultValue={2}
                  disabled
                  label={"buildingOwnerType"}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={8} md={4}>
            <ReferenceInput
                reference={"actors"}
                source={"actor_ids"}
            >
              <AutocompleteArrayInput
                  filterToQuery={(search: any) => ({ name: search })}
                  optionText={(owner: any) => `${owner?owner.firstName + " " + owner.lastName + " " + owner.noActor: ""}`}
                  optionValue="id"
                  fullWidth={true}
                  validate={[required()]}
                  label={"buildingOwner"}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={8} md={4}>
            <TextInput source="address" label="buildingAddress" style={{ width: '100%' }} validate={[required()]}/>
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="type" label="buildingType" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="year" label="buildingYear" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="folio" label="buildingFolio" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="parcel" label="buildingParcel" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <NumberInput source="nbHabitableRoom" style={{ width: '100%' }}/>
          </Grid>

          <Grid item md={4}>
            <ReferenceInput
                reference="buildingTypes"
                source="buildingTypeId"
            >
              <SelectInput optionText="libelle" fullWidth={true} label={t("residenceType.fields")}/>
            </ReferenceInput>
          </Grid>

          <Grid item md={4}>
            <ReferenceInput
                reference="buildingStatus"
                source="buildingStatusId"
            >
              <SelectInput optionText="libelle" fullWidth={true} label={t("buildingStatus.fields")}/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="comment" label="buildingComment" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={8} md={4}>
              <CheckboxInput source="raMention" label={t("resources.buildings.fields.raMention")}/>
          </Grid>
        </Grid>

        <Paper variant="outlined" style={{ width: 'calc(100% - 40px)', padding: '10px 20px' }}>
          <Typography variant="h6" color="textSecondary">
            {t('heatingEquipment')}
          </Typography>
          <Grid container>
            <Grid item md={4}>
              <CheckboxInput source="centralHeating" label={t("centralHeating")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="electricHeating" label={t("electricHeating")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="fuelOilHeating" label={t("fuelOilHeating")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="woodHeating" label={t("woodHeating")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="otherHeating" label={t("otherHeating")} />
            </Grid>
          </Grid>
        </Paper>

        <Paper variant="outlined" style={{ width: 'calc(100% - 40px)', padding: '10px 20px', marginTop: 30 }}>
          <Typography variant="h6" color="textSecondary">
            {t('energySource.name')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ReferenceInput
                  reference="energySources"
                  source="mainEnergyId"
              >
                <SelectInput optionText="libelle" fullWidth={true} label={t("energySource.fields.main")}/>
              </ReferenceInput>
            </Grid>
            <Grid item md={6}>
              <ReferenceInput
                  reference="energySources"
                  source="secondaryEnergyId"
              >
                <SelectInput optionText="libelle" fullWidth={true} label={t("energySource.fields.secondary")}/>
              </ReferenceInput>
            </Grid>
          </Grid>
        </Paper>

        <Paper variant="outlined" style={{ width: 'calc(100% - 40px)', padding: '10px 20px', marginTop: 30 }}>
          <Typography variant="h6" color="textSecondary">
            {t('fireControl')}
          </Typography>
          <Grid container>
            <Grid item md={4}>
              <CheckboxInput source="fireControlBuilding" label={t("fireControlBuilding")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="fireControlAshtray" label={t("fireControlAshtray")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="fireControlPipes" label={t("fireControlPipes")} />
            </Grid>
            <Grid item md={4}>
              <CheckboxInput source="fireControlInstallation" label={t("fireControlInstallation")} />
            </Grid>
            <Grid item md={4}>
              <TextInput source="fireControlDescription" label={t("fireControlDescription")} />
            </Grid>
          </Grid>
        </Paper>

        <Accordion style={{ width: '100%', marginTop: 30 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('tanks')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="tanks" label="tanks">
              <SimpleFormIterator>
                <TextInput source="type" label="tankType" />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('waterMeter.name')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container style={{ width: '100%' }} spacing={2}>
              <Grid item xs={8} md={6}>
                <TextInput source="waterMeterNo" label="waterMeter.no" style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={8} md={12}>
                <ArrayInput source="waterMeterHistorics" label="waterMeter.historic">
                  <SimpleFormIterator>
                    <NumberInput source="m3" label="waterMeter.m3" />
                    <DateInput source="date" label="waterMeter.date" />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('floors')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="floors" label="floors">
              <SimpleFormIterator>
                <ReferenceInput reference="floor_types" source="type.id">
                  <SelectInput label="floorType"
                      optionText={(type: any) =>
                          floorTypeChoices[type.name as keyof typeof floorTypeChoices]
                      }
                  />
                </ReferenceInput>
                <TextInput source="description" label="floorDescription" />
                <TextInput source="renter" label="floorRenter" />
                <TextInput source="totalRooms" label="floorTotalRooms" />
                <TextInput source="ur" label="floorUr" />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('localElectricHeating')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="localElectricHeatings" label="electricHeating">
              <SimpleFormIterator>
                <TextInput source="local" label="heatingLocal" />
                <TextInput source="voltage" label="heatingVoltage" />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('garages')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="garages" label="garages">
              <SimpleFormIterator>
                <TextInput source="floor" label="garageFloor" />
                <TextInput source="walls" label="garageWalls" />
                <TextInput source="ceiling" label="garageCeiling" />
                <TextInput source="flow" label="garageFlow" />
                <TextInput source="places" label="garagePlaces" />
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('ppes')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="ppes" label="ppes">
              <SimpleFormIterator>
                <TextInput source="numPPE" label="numPPE"/>
                <NumberInput source="nbHabitableRoom" label="nbHabitableRoom"/>
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('documents.name')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ArrayInput source="documents">
              <SimpleFormIterator>
                <DocumentAFournirInput source="" documentTypes={documentTypes}/>
              </SimpleFormIterator>
            </ArrayInput>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ width: '100%', marginTop: 20 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('inspectionReports.name')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ReferenceArrayInput
                reference="inspectionReports"
                source="inspectionReport_ids"
            >
              <SelectArrayInput fullWidth={true} label={t("inspectionReports.fields.select")}/>
            </ReferenceArrayInput>
          </AccordionDetails>
        </Accordion>
      </SimpleForm>
    </Create>
  );
}
