import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectArrayInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    usePermissions,
    PasswordInput
} from 'react-admin';


import SignatureInput from '../common/SignatureInput';
import { Grid } from "@mui/material";
export default function UserEdit(props: any, record: any) {
    const {permissions} = usePermissions();
    return (
        //Ajouter <SignatureInput source="signature"/>
        <Edit title="editUser" {...props} mutationMode="pessimistic" redirect={"false"}>
            <SimpleForm>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <TextInput source="email" style={{width: '100%'}}/>
                    </Grid>
                </Grid>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <ReferenceArrayInput reference="roles" source="role_ids" label={"testLabel"}>
                            <AutocompleteArrayInput
                                optionText={(role: any) => `${role?role.name:""}`}
                                optionValue="id"
                                style={{width: '100%'}}
                            />
                        </ReferenceArrayInput>
                    </Grid>
                </Grid>
                {permissions?.includes('superuser') &&
                    <Grid container style={{ width: '100%' }} spacing={2}>
                        <Grid item xs={8} md={4}>
                            <PasswordInput source="password" style={{width: '100%'}}/>
                        </Grid>
                    </Grid>
                }
                <SignatureInput source="signature"/>
            </SimpleForm>
        </Edit>
    );
}
