import React from 'react';
import {
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useTranslate, CreateButton,
  required,
} from 'react-admin';
import { Grid } from '@mui/material';

export default function BuildingOwnerCreate(props: any) {
  const t = useTranslate();

  const phoneTypeChoices = [
    { type: 'mobile', label: t('phoneTypes.mobile') },
    { type: 'primary', label: t('phoneTypes.primary') },
    { type: 'secondary', label: t('phoneTypes.secondary') },
  ];

  return (
    <Create title="createBuildingOwner" {...props}>
      <SimpleForm>

        <Grid container style={{ width: '100%' }} spacing={2}>
          <Grid item xs={8} md={4}>
            <TextInput source="firstName" label="resources.building_owners.fields.firstName" style={{width:'100%'}}  validate={[required()]}/>
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="lastName" label="resources.building_owners.fields.lastName" style={{width:'100%'}}  validate={[required()]}/>
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="address" label="resources.building_owners.fields.address" style={{width:'100%'}} />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextInput source="filiation" label="resources.building_owners.fields.filiation" style={{width:'100%'}} />
          </Grid>
          <Grid item xs={8} md={4}>
            <ReferenceInput
                reference="localities"
                source="locality.id"
                label="locality"
                filterToQuery={(search) => ({ locality: search })}
            >
              <AutocompleteInput
                  source="locality"
                  optionValue="id"
                  validate={[required()]}
                  optionText={(choice: any) => {
                    if(choice != null){
                      return choice.code + " " + choice.locality;
                    } else {
                      return "";
                    }
                  }}
                  fullWidth={true}
              />
            </ReferenceInput>
          </Grid>

          <Grid item xs={4} md={3} style={{ marginTop: 20 }}>
            <CreateButton resource="localities" />
          </Grid>
        </Grid>

        <ArrayInput source="phones">
          <SimpleFormIterator>
            <TextInput source="phoneNumber" label="phoneNumber" />
            <SelectInput
                source="type"
                label="phoneType"
                choices={phoneTypeChoices}
                optionValue="type"
                optionText="label"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}
