import React from 'react';
import { Datagrid, DeleteButton, EditButton, List, TextField } from 'react-admin';

export default function NotificationTypeList(props: any) {
  return (
    <List {...props} exporter={false} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="label" />
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
}
