import { fetchUtils } from 'react-admin';

export default function httpClient(url: string, options: any = {}) {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
  }

  // include cookies into request
  options.credentials = 'include';

  return fetchUtils
    .fetchJson(url, options)
    .then((response) => {
      return { ...response, json: response.json.data };
    })
    .catch((err) => {
      // here we can customize how the end notification message will look like
      const message = err.body.error.message;
      err.message = message;

      throw err;
    });
}

export function isDransgrid() {
  return window.location.hostname.includes('dransgrid');
}

export function isLiddes() {
  return window.location.hostname.includes('liddes');
}
