import { Link } from '@mui/material';
import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  FunctionField,
  Filter,
  SearchInput,
  useTranslate, TopToolbar, CreateButton,
} from 'react-admin';
import ReportButton from "../customButton/ReportButton";

const NameFilter = (props: any) => (
        <Filter {...props}>
            <SearchInput source="name" resettable alwaysOn />
            <ReportButton />
        </Filter>
);

const ListActions = (props: any) => {
  const {
    className,
    basePath,
  } = props;
  return (
      <TopToolbar className={className}>
        <CreateButton href={basePath}/>

      </TopToolbar>
  );
};

export default function BuildingOwnerList() {
  const t = useTranslate();

  return (
    <List filters={<NameFilter />} actions={<ListActions/>} exporter={false} bulkActionButtons={false}>
      <Datagrid>
        <FunctionField
          render={(owner: any) => `${owner.firstName} ${owner.lastName}`}
          label="ownerName"
          sortBy={"Name"}
        />
        <TextField source="address" />
        <TextField source="locality" label="ownerLocality" sortBy="locality"/>
        <TextField source="nbBuildings" label="totalBuildings" />
        <FunctionField
          render={(owner: any) => {
            const query = new URLSearchParams({ filter: JSON.stringify({ ownerId: owner.id }) });

            return <Link href={`/buildings?${query.toString()}`}>{t('showBuildings')}</Link>;
          }}
        />
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
}
