import {
    Form,
    PasswordInput,
    useTranslate,
    SaveButton,
    useAuthProvider, Identifier,
} from 'react-admin';
import {Alert, Paper, Snackbar} from '@mui/material';
import React from "react";

import api from '../../api';
import API from "../../api";

type Error = {
    confirmPassword?: string,
    password?: string
}




function ChangePassword() {
    const t = useTranslate();
    const authProvider = useAuthProvider();
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    let userId : Identifier;
    if(typeof authProvider.getIdentity === 'function'){
        const identity = authProvider.getIdentity();
        identity.then(values => {
            const re = /"/gi;
            if(typeof values.id === 'string') {
                userId = values.id.replace(re, '');
            } else {
                userId = values.id;
            }
        });
    }

    function editPassword(data){
        const password = data.password;
        setSaving(true);
        api.put('/users/changePassword/' + userId, { password }).then((response) => {
            setOpen(true);
            setSaving(false);
        }).catch((error) => {
            console.log(error);
            setOpen(true);
            setSaving(false);
        })
    }

    function handleClose(){
        setOpen(false);
    }

    function handleCloseError(){
        setOpenError(false);
    }

    function validatePassword(values){
        const errors: Error = {};
        let empty : boolean = false;

        if(values.password == ''){
            empty = true;
            errors.password = t('changePasswordForm.errors.empty');
        }

        if(values.confirmPassword == ''){
            empty = true;
            errors.confirmPassword = t('changePasswordForm.errors.empty');
        }

        if (empty) return errors;

        if(values.password.length < 8){
            errors.password = t("changePasswordForm.errors.toShort");
        }

        if(values.password != values.confirmPassword){
            errors.confirmPassword = t('changePasswordForm.errors.confirmDifferent');
        }
        return errors;
    }

    return(
        <Paper variant="outlined" style={{ width:'calc(100% - 40px)', padding: '10px 20px', marginTop: '20px' }}>
            <Form validate={validatePassword} onSubmit={editPassword}>
                <PasswordInput
                    name={"password"}
                    source={"password"}
                    label={"changePasswordForm.fields.newPassword"}
                    style={{width: '30%'}}
                />
                <br />
                <PasswordInput
                    name={"confirmPassword"}
                    source={"confirmPassword"}
                    label={"changePasswordForm.fields.confirmPassword"}
                    style={{width: '30%'}}
                />
                <br />
                <SaveButton saving={saving} />
            </Form>
            <Snackbar
                open={open}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={3000}
                onClose={handleClose}
                message={t("changePasswordForm.message.succes")}
            />
            <Snackbar
                open={openError}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={3000}
                onClose={handleCloseError}
            >
                <Alert severity="error">{t("changePasswordForm.message.error")}</Alert>
            </Snackbar>
        </Paper>

    )

}
export default <ChangePassword />