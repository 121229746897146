import React from 'react';
import {
  Datagrid,
  DeleteButton,
  EditButton,
  EmailField,
  FunctionField,
  List,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { checkSuperuser } from '../../raUtils/authProvider';
import {Chip} from "@mui/material";

export default function UserList(props: any) {
  const t = useTranslate();
  const { isLoading, permissions } = usePermissions();

  const isSuperuser = !isLoading && checkSuperuser(permissions);

  return (
    <List {...props} exporter={false} bulkActionButtons={false}>
      <Datagrid>
        <EmailField source="email" />
        <FunctionField
          label="userRole"
          render={(user: any) => user.roles && user.roles.map(role => <Chip style={{marginRight:'5px'}} key={role.id} label={role.name} />)}
        />
        {isSuperuser && <EditButton />}
        {isSuperuser && <DeleteButton mutationMode="pessimistic" />}
      </Datagrid>
    </List>
  );
}
