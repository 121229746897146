import { AppBar, Layout, UserMenu, MenuItemLink, useTranslate, useLogout } from 'react-admin';
import { Box, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';
import theme from '../../theme';

import LocaleSwitcher from '../common/LocaleSwitcher';

const CustomUserMenu = (props: any) => {
  const t = useTranslate();

  return (
    <UserMenu {...props}>
        <MenuItemLink
            to="/notify"
            sidebarIsOpen
            primaryText={t('notifyCitizens')}
            leftIcon={<NotificationsIcon />}
        />
        <MenuItemLink
            to={"/changePassword"}
            sidebarIsOpen
            primaryText={t('changePasswordForm.fields.changePassword')}
            leftIcon={<LockResetIcon />}
        />
        <MenuItemLink
            to={"/login"}
            onClick={useLogout()}
            sidebarIsOpen
            primaryText={t('logout')}
            leftIcon={<LogoutIcon />}
        />
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => (
  <AppBar {...props} style={{ backgroundColor: theme.palette.primary.layout.appBar }} userMenu={<CustomUserMenu />}>
    <Typography variant="h6" id="react-admin-title"></Typography>

    <div style={{ marginLeft: 'auto' }}>
      <LocaleSwitcher />
    </div>
  </AppBar>
);

const CustomLayout = (props: any) => <Layout {...props}  appBar={CustomAppBar} />;

export default CustomLayout;
