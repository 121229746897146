export const messageMaxLength = 918;
export const unicodeMessageMaxLength = 402;
export const maxSMS = 6;
export const costPerSMS = 0.08;
export const nbFreeSMS = 10000;



export function SMSLeft(count){
    if(count >= nbFreeSMS) return 0;
    return nbFreeSMS - count;
}
  
export function SMSCost(count){
    if(count <= nbFreeSMS) return 0;
    return (count - nbFreeSMS) * 0.08;
}