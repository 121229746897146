import React from 'react';
import {Edit, SimpleForm, TextInput, required, useRefresh} from 'react-admin';

export default function LocalityEdit(props: any) {
  return (
    <Edit title="editLocality" {...props} mutationMode="pessimistic" redirect={() => window.location.reload()}>
      <SimpleForm>
        <TextInput source="code" validate={[required()]}/>
        <TextInput source="locality" validate={[required()]}/>
      </SimpleForm>
    </Edit>
  );
}
