import React, { useCallback } from 'react';
import { useLocale, useSetLocale } from 'react-admin';
import { Select, MenuItem } from '@mui/material';

export default function LocaleSwitcher() {
  const locale = useLocale();
  const setLocale = useSetLocale();

  const handleLanguageChange = useCallback(
    (e) => {
      const selectedLng = e.target.value as string;

      localStorage.setItem('language', selectedLng);
      setLocale(selectedLng);
    },
    [setLocale]
  );

  return (
    <div>
      <Select value={locale} onChange={handleLanguageChange} style={{ color: '#fff' }}>
        <MenuItem value="fr">Français</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </div>
  );
}
