import React from "react";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useTranslate } from "react-admin";

import {API_URL} from "../../api"

const ReportButton = (props: any) => {
    const t = useTranslate();
    const translationMap = {
        button: t('customButton.reportButton.button'),
        error: t('customButton.reportButton.error'),
    }

    const label = (props.label == undefined)?translationMap.button: props.label;

    function getReport(){
        const url = API_URL + '/reports' + props.url;
        window.open(url, "_blank");
    }

    return (
        <div>
            <Button
                color="primary"
                size="small"
                component="label"
                startIcon={<PictureAsPdfIcon />}
                onClick={() => { getReport(); }}
            >
                {label}
            </Button>
        </div>
    )
}

export default ReportButton;