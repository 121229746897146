import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper, TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {useEffect} from 'react';
import {
  DateInput,
  Edit,
  SimpleForm,
  FileInput,
  TextInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput, ReferenceArrayInput, FormDataConsumer,
  NumberInput, BooleanInput, ImageField, FileField,
  AutocompleteArrayInput,
  required, useInput, useResourceDefinitions,
} from 'react-admin';

import API from "../../api"
import CheckboxInput from '../common/CheckboxInput';
import ReportButton from "../customButton/ReportButton";
import DocumentAFournirInput from "../common/DocumentAFournirInput";
import TextArrayInput from "../common/TextArrayInput";

import { useLocation } from 'react-router';

export default function BuildingEdit(props: any, record: any) {

  const location = useLocation();
  const id = location.pathname.replace("/buildings/", "");

  const t = useTranslate();

  const [garageTot, setGarageTot] = React.useState(0);
  const [robinetTot, setRobinetTot] = React.useState(0);
  const [urTot, setUrTot] = React.useState(0);
  //const [habitableTot, setHabitableTot] = React.useState(0);
  const [source, setSource] = React.useState("");
  const [documentTypes, setDocumentTypes] = React.useState([]);

  useEffect(() => {
    API.get('/buildings/data/' + id)
        .then(res => {
          setRobinetTot(res.data.data.robinetTot);
          setUrTot(res.data.data.urTot);
          setGarageTot(res.data.data.garageTot);
          //setHabitableTot(res.data.data.habitableTot);
        })
        .catch(err => {
          console.log(err);
        })
    API.get('/documentTypes')
        .then(res => {
          const documents = res.data.data.map((document: { name: string; id: number; }) => {
            return {label: document.name, value: document.id}
          })
          setDocumentTypes(documents);
        })
        .catch(err => {
          console.log(err);
        })
  }, []);
  const floorTypeChoices = {
    basement: t('basement'),
    reserve_floor: t('reserve_floor'),
    ground_floor: t('ground_floor'),
    first_floor: t('first_floor'),
    second_floor: t('second_floor'),
    third_floor: t('third_floor'),
  };

  /**
   * Utilisé pour fixe le bug des pièces.
   * Sans ça s'il n'y a pas de refresh sur la page,
   * les pièces ne s'affiche pas correctement
   */
  const hasToReloadForBug = () => {
    const jsonObj = localStorage.getItem('hasToReload');
    let hasToReload;
    const now = new Date();
    if(jsonObj == null) {
      hasToReload = true;
    } else {
      const lastReload = new Date(JSON.parse(jsonObj));
      // @ts-ignore
      const deltaS = (now - lastReload)/1000;
      hasToReload = deltaS > 6;
    }
    if(hasToReload){
      localStorage.setItem('hasToReload', JSON.stringify(now.toJSON()));
      window.location.reload();
    }
  }

  //Check si le bug peut se produire, si oui recharger la page
  API.get('/buildings/' + id)
      .then(res => {
        if(res.data.data.documents.length > 0){
          hasToReloadForBug();
        }
      })
      .catch(err => {
        console.log(err);
      })
  //Fin Check


  const onTypeChange = (event: any, values: any) => {
    const tempSource = event.target.value == 1 ? "building_owners" : "actors";
    setSource(tempSource);
  }

  return (
      <Edit title="editBuilding" {...props} mutationMode="pessimistic">
        <SimpleForm>

          <Grid container style={{ width: '100%' }} spacing={2}>
            <Grid item xs={8} md={4}>
              <TextInput source="id" disabled style={{ width: '100%' }} />
            </Grid>
          </Grid>

          <Grid container style={{ width: '100%' }} spacing={2}>
            <Grid item xs={8} md={4}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <ReferenceInput reference={"ownerTypes"} source={"ownerType_id"} onChange={(e) => onTypeChange(e, null)}>
                      <SelectInput
                          fullWidth={true}
                          disabled={formData.ownerType_id == 2 && source== ""}
                          label={"buildingOwnerType"}
                      />
                    </ReferenceInput>
                }
              </FormDataConsumer>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) => (formData.ownerType_id == 1 || source == "building_owners") &&
                  <Grid item xs={8} md={4}>
                    <ReferenceInput
                        reference={"building_owners"}
                        source={"owner_id"}
                    >
                      <AutocompleteInput
                          optionText={(owner: any) => `${owner?owner.firstName + " " + owner.lastName: ""}`}
                          optionValue="id"
                          fullWidth={true}
                          validate={[required()]}
                          filterToQuery={(search: any) => ({ name: search })}
                          label={"buildingOwner"}
                      />
                    </ReferenceInput>
                  </Grid>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => (formData.ownerType_id == 2 || source == "actors") &&
                  <Grid item xs={8} md={4}>
                    <ReferenceArrayInput
                        reference={"actors"}
                        source={"actor_ids"}
                        label={"buildingOwner"}
                    >
                      <AutocompleteArrayInput
                          optionText={(owner: any) => `${owner?owner.firstName + " " + owner.lastName + " " + owner.noActor: ""}`}
                          optionValue="id"
                          fullWidth={true}
                          validate={[required()]}
                          filterToQuery={(search: any) => ({ name: search })}
                      />
                    </ReferenceArrayInput>
                  </Grid>
              }
            </FormDataConsumer>

            <Grid item xs={8} md={4}>
              <TextInput source="address" label="buildingAddress" style={{ width: '100%' }} validate={[required()]}/>
            </Grid>
            <Grid item xs={8} md={4}>
              <TextInput source="type" label="buildingType" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={8} md={4}>
              <TextInput source="year" label="buildingYear" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={8} md={4}>
              <TextInput source="folio" label="buildingFolio" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={8} md={4}>
              <TextInput source="parcel" label="buildingParcel" style={{ width: '100%' }} />
            </Grid>

            <Grid item xs={8} md={4}>

              <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <NumberInput source="nbHabitableRoom" disabled={formData.ppes?.length > 0} style={{ width: '100%' }}/>
                }
              </FormDataConsumer>
            </Grid>

            <Grid item md={4}>
              <ReferenceInput
                  reference="buildingTypes"
                  source="buildingTypeId"

              >
                <SelectInput optionText="libelle" fullWidth={true} label={t("residenceType.fields")}/>
              </ReferenceInput>
            </Grid>

            <Grid item md={4}>
              <ReferenceInput
                  reference="buildingStatus"
                  source="buildingStatusId"
              >
                <SelectInput optionText="libelle" fullWidth={true} label={t("buildingStatus.fields")}/>
              </ReferenceInput>
            </Grid>
            <Grid item xs={8} md={4}>
              <TextInput source="comment" label="buildingComment" style={{ width: '100%' }} />
            </Grid>
            <Grid item xs={8} md={4}>
                <CheckboxInput source="raMention" label={t("resources.buildings.fields.raMention")}/>
            </Grid>
            {/*<FileInput source="files" label="Fichier" style={{ width: '100%' }} >*/}
            {/*  <FileField source="src" title="title" />*/}
            {/*</FileInput>*/}
          </Grid>

          <Paper variant="outlined" style={{ width:'calc(100% - 40px)', padding: '10px 20px' }}>
            <Typography variant="h6" color="textSecondary">
              {t('heatingEquipment')}
            </Typography>
            <Grid container style={{ width: '100%' }}>
              <Grid item md={4}>
                <CheckboxInput source="centralHeating" label={t("centralHeating")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="electricHeating" label={t("electricHeating")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="fuelOilHeating" label={t("fuelOilHeating")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="woodHeating" label={t("woodHeating")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="otherHeating" label={t("otherHeating")}/>
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" style={{ width: 'calc(100% - 40px)', padding: '10px 20px', marginTop: 30 }}>
            <Typography variant="h6" color="textSecondary">
              {t('energySource.name')}
            </Typography>
            <Grid container spacing={2} style={{ width: '100%' }}>
              <Grid item md={6}>
                <ReferenceInput
                    reference="energySources"
                    source="mainEnergyId"
                >
                  <SelectInput optionText="libelle" fullWidth={true} label={t("energySource.fields.main")}/>
                </ReferenceInput>
              </Grid>
              <Grid item md={6}>
                <ReferenceInput
                    reference="energySources"
                    source="secondaryEnergyId"
                >
                  <SelectInput optionText="libelle" fullWidth={true} label={t("energySource.fields.secondary")}/>
                </ReferenceInput>
              </Grid>
            </Grid>
          </Paper>

          <Paper variant="outlined" style={{ width: 'calc(100% - 40px)', padding: '10px 20px', marginTop: 30 }}>
            <Typography variant="h6" color="textSecondary">
              {t('fireControl')}
            </Typography>
            <Grid container>
              <Grid item md={4}>
                <CheckboxInput source="fireControlBuilding" label={t("fireControlBuilding")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="fireControlAshtray" label={t("fireControlAshtray")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="fireControlPipes" label={t("fireControlPipes")} />
              </Grid>
              <Grid item md={4}>
                <CheckboxInput source="fireControlInstallation" label={t("fireControlInstallation")} />
              </Grid>
              <Grid item md={4}>
                <TextInput source="fireControlDescription" label={t("fireControlDescription")} />
              </Grid>
            </Grid>
          </Paper>

          <Accordion style={{ width: '100%', marginTop: 30 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('tanks')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="tanks" label="tanks">
                <SimpleFormIterator>
                  <TextInput source="type" label="tankType" />
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('waterMeter.name')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container style={{ width: '100%' }} spacing={2}>
                <Grid item xs={8} md={6}>
                  <TextInput source="waterMeterNo" label="waterMeter.no" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={8} md={12}>
                  <ArrayInput source="waterMeterHistorics" label="waterMeter.historic">
                    <SimpleFormIterator>
                      <NumberInput source="m3" label="waterMeter.m3" />
                      <DateInput source="date" label="waterMeter.date" />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('floors') + " | " + t('nbRobTot')  + ": " + robinetTot + " | " + t('nbUrTot') + ": " + urTot}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="floors" label="floors" fullWidth>
                <SimpleFormIterator>
                  <ReferenceInput reference="floor_types" source="type.id"  style={{ width: '100%' }}>
                    <SelectInput label="floorType"
                        optionText={(type: any) => t(type.name)
                        }
                    />
                  </ReferenceInput>
                  <TextInput source="description" label="floorDescription" style={{ width: '100%' }} />
                  <NumberInput source="renter" label="floorRenter" style={{ width: '100%' }} />
                  <NumberInput source="totalRooms" label="floorTotalRooms" style={{ width: '100%' }} />
                  <NumberInput source="ur" label="floorUr" style={{ width: '100%' }} />
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('localElectricHeating')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="localElectricHeatings" label="electricHeating">
                <SimpleFormIterator>
                  <TextInput source="local" label="heatingLocal" />
                  <NumberInput source="voltage" label="heatingVoltage" />
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('garages') + " | " + t('nbGarageTot') + ": " + garageTot}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="garages" label="garages">
                <SimpleFormIterator>
                  <TextInput source="floor" label="garageFloor" />
                  <TextInput source="walls" label="garageWalls" />
                  <TextInput source="ceiling" label="garageCeiling" />
                  <TextInput source="flow" label="garageFlow" />
                  <TextInput source="places" label="garagePlaces" />
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('ppes')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="ppes" label="ppes">
                <SimpleFormIterator>
                  <TextInput source="numPPE" label="numPPE"/>
                  <NumberInput source="nbHabitableRoom" label="nbHabitableRoom"/>
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('documents.name')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayInput source="documents">
                <SimpleFormIterator>
                  <DocumentAFournirInput source="" documentTypes={documentTypes}/>
                </SimpleFormIterator>
              </ArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('inspectionReports.name')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ReferenceArrayInput
                  reference="inspectionReports"
                  source="inspectionReport_ids"
              >
                <SelectArrayInput fullWidth={true} label={t("inspectionReports.fields.select")}/>
              </ReferenceArrayInput>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('buildAuthorization.name')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <TextInput source="buildAuthorizationProjectName" label="buildAuthorization.fields.name" style={{ width: '100%' }} />
                </Grid>
                <Grid item md={4}>
                  <DateInput source="buildAuthorizationDate" label="buildAuthorization.fields.date" style={{ width: '100%' }} />
                </Grid>
                <Grid item md={3}>
                  <CheckboxInput source="buildAuthorizationNotice" label={t("buildAuthorization.fields.notice")} style={{ width: '100%' }} />
                </Grid>
                <Grid item md={3}>
                  <CheckboxInput source="buildAuthorizationFire" label={t("buildAuthorization.fields.fire")} style={{ width: '100%' }} />
                </Grid>
                <Grid item md={3}>
                  <CheckboxInput source="buildAuthorizationQuality" label={t("buildAuthorization.fields.quality")} style={{ width: '100%' }} />
                </Grid>
                <Grid item md={3}>
                  <CheckboxInput source="buildAuthorizationPermissionToLive" label={t("buildAuthorization.fields.permissionToLive")} style={{ width: '100%' }} />
                </Grid>
                <Grid item md={12}>
                  <TextInput source="buildAuthorizationComments" label="buildAuthorization.fields.comments" style={{ width: '100%' }} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: '100%', marginTop: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{"Export pdf"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container style={{ width: '100%' }} spacing={2}>
                <Grid item xs={8} md={6}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <ReportButton url={"/building/permissionToLive/" + formData.id} label={t("exportButton.permissionToLive")} style={{ width: '100%' }} />
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={8} md={6}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <ReportButton url={"/building/authorization/" + formData.id} label={t("exportButton.authorization")} style={{ width: '100%' }} />
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={8} md={6}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <ReportButton url={"/building/documents/" + formData.id} label={t("exportButton.documents")} style={{ width: '100%' }} />
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={8} md={6}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <ReportButton url={"/building/inspectionReport/" + formData.id} label={t("exportButton.permissionToLiveReport")} style={{ width: '100%' }} />
                    }
                  </FormDataConsumer>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </SimpleForm>
      </Edit>
  );
}
