import React from 'react';
import { createBrowserHistory } from 'history';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import PeopleIcon from '@mui/icons-material/People';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HomeIcon from '@mui/icons-material/Home';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import FaceIcon from '@mui/icons-material/Face';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FlagIcon from '@mui/icons-material/Flag';
import SmsIcon from '@mui/icons-material/Sms';

import authProvider, { checkSuperuser, checkGuest, checkBuildingManagement } from './raUtils/authProvider';
import dataProvider from './raUtils/dataProvider';

import UserList from './components/user/UserList';
import UserCreateForm from './components/user/UserCreate';
import UserEditForm from './components/user/UserEdit';
import ActorList from './components/actor/ActorList';
import ActorEditForm from './components/actor/ActorEdit';
import ActorCreateForm from './components/actor/ActorCreate';
import BuildingOwnerList from './components/buildingOwner/BuildingOwnerList';
import BuildingOwnerEditForm from './components/buildingOwner/BuildingOwnerEdit';
import BuildingOwnerCreateForm from './components/buildingOwner/BuildingOwnerCreate';
import CitizenList from './components/citizen/CitizenList';
import CitizenEditForm from './components/citizen/CitizenEdit';
import CitizenCreateForm from './components/citizen/CitizenCreate';
import LocalityList from './components/locality/LocalityList';
import LocalityCreateForm from './components/locality/LocalityCreate';
import LocalityEditForm from './components/locality/LocalityEdit';
import BuildingList from './components/building/BuildingList';
import BuildingCreateForm from './components/building/BuildingCreate';
import BuildingEditForm from './components/building/BuildingEdit';
import NotificationTypeCreateForm from './components/notificationType/NotificationTypeCreate';
import NotificationTypeEditForm from './components/notificationType/NotificationTypeEdit';
import VillageList from './components/village/VillageList';
import i18nProvider from './raUtils/i18nProvider';
import CustomLayout from './components/layout/Layout';
import CustomLoginPage from './components/login/Login';
import SignupPage from './components/signup/Signup';
import SMSConfirmationPage from './components/confirmSMS/SMSConfirmation';
import MailingPage from './components/mailing/Mailing';
import ChangePassword from "./components/user/ChangePassword";
import NotificationTypeList from './components/notificationType/NotificationTypeList';
import theme from './theme';
import {Route} from "react-router-dom";
import CommuneList from './components/commune/CommuneList';
import { isDransgrid } from './raUtils/httpClient';
import SessionSMSList from './components/sessionSMS/SessionSMSList';
import SessionSMSShow from './components/sessionSMS/SessionSMSShow';
const history = createBrowserHistory();

function App() {
  // @ts-ignore
    return (
    <Admin
      theme={theme}
      layout={CustomLayout}
      loginPage={CustomLoginPage}
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
        <CustomRoutes>
            <Route path="/notify" element={MailingPage} />
            <Route path="/changePassword" element={ChangePassword} />
        </CustomRoutes>
        <CustomRoutes noLayout>
            <Route path="/signup" element={SignupPage} />
            <Route path="/confirm" element={SMSConfirmationPage} />
        </CustomRoutes>
      {(permissions) => {
        const isSuperuser = checkSuperuser(permissions);
        const isGuest = checkGuest(permissions);
        const buildingManagement = checkBuildingManagement(permissions);

        return [
          !isGuest && (
            <Resource
              name="users"
              list={UserList}
              icon={PeopleIcon}
              create={isSuperuser ? UserCreateForm : undefined}
              edit={isSuperuser ? UserEditForm : undefined}
            />
          ),
          !isGuest && (
              <Resource
                  name="roles"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="actors"
                  list={ActorList}
                  icon={SupervisedUserCircleIcon}
                  create={ActorCreateForm}
                  edit={ActorEditForm}
              />
          ),
          !isGuest && buildingManagement && (
            <Resource
              name="building_owners"
              list={BuildingOwnerList}
              icon={SupervisedUserCircleIcon}
              create={BuildingOwnerCreateForm}
              edit={BuildingOwnerEditForm}
            />
          ),
          !isGuest && buildingManagement && (
            <Resource
              name="buildings"
              list={BuildingList}
              icon={HomeIcon}
              create={BuildingCreateForm}
              edit={BuildingEditForm}
            />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="documents"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="energySources"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="buildingStatus"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="buildingTypes"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="inspectionReports"
              />
          ),
          !isGuest && buildingManagement && (
              <Resource
                  name="ownerTypes"
              />
          ),
          !isGuest && buildingManagement && (
            <Resource
              name="localities"
              list={LocalityList}
              icon={LocalPostOfficeIcon}
              create={LocalityCreateForm}
              edit={LocalityEditForm}
            />
          ),
          !isGuest && (
            <Resource
                name="citizens"
                icon={FaceIcon}
                list={CitizenList}
                create={CitizenCreateForm}
                edit={CitizenEditForm}
            />
          ),
          !isGuest && (
            <Resource
              name="communes"
              list={CommuneList}
              icon={FlagIcon}
            />
          ),
          !isGuest && (
            <Resource
              name="villages"
              list={VillageList}
              icon={FilterHdrIcon}
            />
          ),
          !isGuest && (
            <Resource
              name="sessionSMS"
              list={SessionSMSList}
              show={SessionSMSShow}
              icon={SmsIcon}
            />
          ),
          !isGuest && !isDransgrid() && (
            <Resource
              name="notification_types"
              list={NotificationTypeList}
              icon={NotificationsIcon}
              create={NotificationTypeCreateForm}
              edit={NotificationTypeEditForm}
            />
          ),
          !isGuest && buildingManagement && (
            <Resource name="floor_types" />
          ),
        ];
      }}
    </Admin>
  );
}

export default App;
