import React from 'react';
import { Create, PasswordInput, SimpleForm, TextInput, required } from 'react-admin';

export default function UserCreate(props: any) {
  return (
    <Create title="createUser" {...props}>
      <SimpleForm>
        <TextInput source="email" validate={[required()]} />
        <PasswordInput source="password" validate={[required()]} />
      </SimpleForm>
    </Create>
  );
}
