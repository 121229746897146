import React, {useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import SignaturePad from 'react-signature-canvas';
import {useController} from "react-hook-form";
import {useInput} from "react-admin";
const styles = require('./styles.module.css');

const sanitizeRestProps = ({
                               component,
                               defaultValue,
                               formClassName,
                               initializeForm,
                               input,
                               isRequired,
                               label,
                               locale,
                               meta,
                               options,
                               optionText,
                               optionValue,
                               record,
                               resource,
                               allowEmpty,
                               source,
                               textAlign,
                               translate,
                               translateChoice,
                               ...rest
                           }: any) => rest;

export default (props) => {

    const {
        field
    } = useInput(props);

    let sigPad : any;

    const clear = () => {
        sigPad.clear();
        const dataUrl = sigPad.toDataURL('image/png');
        field.onChange(dataUrl);
    }

    const handleChange = (event: React.ChangeEvent, value: boolean) => {
        const dataUrl = sigPad.toDataURL('image/png');
        field.onChange(dataUrl);
    };
    const { className, isRequired, source, resource, options, ...rest } = props;
    return (
        <FormGroup className={className} {...sanitizeRestProps(rest)}>
            <FormControlLabel
                label = ""
                style={{margin: "auto"}}
                control={
                    <div className={styles.sigContainer}>
                        <SignaturePad onEnd={handleChange} throttle={1} canvasProps={{className: styles.sigPad}}
                                      ref={(ref) => {
                                          sigPad = ref
                                      }}/>
                    </div>
                }
            />
            <div>
                <p className={styles.buttons} onClick={clear}>
                    Clear
                </p>
            </div>
            <img className={styles.sigImage} src={field.value}/>
        </FormGroup>
    );
}
