import React, { HtmlHTMLAttributes, createElement, ReactNode, useRef, useEffect, lazy } from 'react';
import { Route } from 'react-router-dom';
import { Notification, TitleComponent } from 'react-admin';

import { Card, Avatar, Theme, Typography, Alert } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SignupForm from './SignupForm';
import theme from '../../theme';
import { isDransgrid } from '../../raUtils/httpClient';

export interface LoginProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  backgroundImage?: string;
  children?: ReactNode;
  classes?: object;
  className?: string;
  theme?: object;
  title?: TitleComponent;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    card: {
      minWidth: 300,
      margin: 'auto',
      maxWidth: '80%',
    },
    avatar: {
      margin: '0em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      // @ts-ignore
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' }
);

const SignupPage: React.FunctionComponent<LoginProps> = (props) => {
  const { title, classes: classesOverride, className, children, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(props);

  const backgroundImage = isDransgrid() ? 'signup-background.jpg' : 'https://www.liddes.ch/images/banner-interne.jpg';

  

  useEffect(() => {
    let backgroundImageLoaded = false;

    const updateBackgroundImage = () => {
      if (!backgroundImageLoaded && containerRef.current) {
        containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
        backgroundImageLoaded = true;
      }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
      if (backgroundImage) {
        const img = new Image();
        img.onload = updateBackgroundImage;
        img.src = backgroundImage;
      }
    };

    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  }, []);

  return (
      <ThemeProvider theme={theme}>
        <div className={classes.main} {...rest} ref={containerRef}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              {isDransgrid() ? 
                <div style={{ margin: 'auto', marginTop: '1em', width: 400, textAlign: 'center' }}>
                  <img
                    src="dransgrid-logo-black.png"
                    style={{ maxWidth: '80%' }}
                    alt="Commune de Liddes"
                  />
                </div>
                :
                <Typography style={{margin:'1em'}} variant="h4" align="center" gutterBottom>
                  Inscription aux notifications
                </Typography>
              }
            </div>
            <SignupForm />
          </Card>
        </div>
      </ThemeProvider>
  );

  /*return (
    <ThemeProvider theme={theme}>
      <div className={classes.main} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <AccountCircleIcon />
            </Avatar>
          </div>
          <SignupForm />
        </Card>
        {createElement(Notification)}
      </div>
    </ThemeProvider>
  );*/
};

export default <SignupPage />;
