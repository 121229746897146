import React from 'react';
import {
  Datagrid,
  DeleteButton,
  List,
  TextField,
  Filter,
  SearchInput,
  EditButton,
} from 'react-admin';

const NameFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" resettable alwaysOn />
  </Filter>
);

export default function VillageList(props: any) {
  return (
    <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', }}>
      <List {...props} filters={<NameFilter />} exporter={false}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="commune.name" label="Commune" />
        </Datagrid>
      </List>
      <p style={{width: '100%', textAlign: 'center', marginBottom: '50px'}}>Pour ajouter un village, contacter l'administrateur de l'application en envoyant un mail à <a href="mailto:info@jm-contactless.ch">info@jm-contactless.ch</a></p>
    </div>
  );
}
