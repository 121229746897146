import { useEffect, useState } from "react";
import { FunctionField, RichTextField, Show, TextField, useNotify, useRecordContext } from "react-admin";
import API from "../../api";
import { DataGrid, GridColumnHeaderParams } from "@mui/x-data-grid";
import { useLocation } from "react-router";
import { formatPhone } from "../../raUtils/format";
import { Alert, Box, Button, Checkbox, Chip, FormControlLabel, IconButton, styled } from "@mui/material";
import ExcelJs from 'exceljs';
import {saveAs} from 'file-saver';
import { ImportExportOutlined, RefreshOutlined } from "@mui/icons-material";

export default function SessionSMSShow(props: any) {

  const notify = useNotify();

  const [messages, setMessages] = useState([]);
  const [hideDelivered, setHideDelivered] = useState(true);
  const [nbDelivred, setNbDelivred] = useState(0);
  const [nbNotReceived, setNbNotReceived] = useState(0);
  const [nbRejected, setNbRejected] = useState(0);
  const [nbInProgress, setNbInProgress] = useState(0);

  const statusMapping = {
    "a": {label: "L'opérateur mobile a reçu le message", final: false},
    "b": {label: "L'opérateur mobile a reçu le message", final: false},
    "d": {label: "Délivré", final: true},
    "e": {label: "Erreur", final: true},
    "f": {label: "Erreur", final: true},
    "j": {label: "Rejeté", final: true},
    "q": {label: "Le message est en attente sur le serveur", final: false},
    "r": {label: "Le message a été envoyé à l'opérateur", final: false},
    "s": {label: "Le message est programmé pour être envoyé plus tard", final: false},
  }

  const location = useLocation();
  const id = location.pathname.split("/")[2]

  useEffect(() => {
    loadMessages();
  }, []);

  function status(status: string) {
    if (status in statusMapping) {
      return statusMapping[status];
    }
    return {label: "Inconnu", final: false};
  }

  /**
   * Charge les messages de la session et met à jour les différents compteurs
   */
  function loadMessages(){
    API.get(`/messagesSMS/bySession/${id}`).then((response) => {
      setMessages(response.data.data);
      let delivered = 0;
      let notReceived = 0;
      let rejected = 0;
      let inProgress = 0;
      for(const message of response.data.data){
        if(message.status == "d") delivered++;
        else if(message.status == "j") rejected++;
        else if(!status(message.status).final) {
          inProgress++;
        }
        else notReceived++;
      }
      setNbDelivred(delivered);
      setNbNotReceived(notReceived);
      setNbRejected(rejected);
      setNbInProgress(inProgress);
    });
  }

  /**
   * Refresh tout les messages dont le status n'est pas final
   */
  function refreshMessages(){
    const ids: number[] = [];
    for (const message of messages as any[]) {
      if(!status(message.status).final) ids.push(message.id);
    }

    notify("Rafraîchissement des messages en cours")

    API.post(`/messagesSMS/refresh`, {ids}).then((response) => {
      loadMessages();
    });
  }

  function filterRows(rows: any) {
    if(!hideDelivered) return rows;
    return rows.filter((row: any) => row.status != "d");
  }
  
  const columns = [
    { 
      field: 'receiver', sortable: true, width: 300,
      valueGetter: (params) => `${formatPhone(params.row.receiver)}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Destinataire'}
        </strong>
      ),
    },
    { 
      field: 'country', sortable: true,
      valueGetter: (params) => `${params.row.country}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Pays'}
        </strong>
      ),
    },
    { 
      field: 'status', sortable: true, width: 400,
      valueGetter: (params) => `${status(params.row.status).label}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Statut'}
        </strong>
      ),
    },
    { 
      field: 'sender', sortable: true,
      valueGetter: (params) => `${params.row.sender}`,
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {'Expéditeur'}
        </strong>
      ),
    },
  ]

  function exporter(messages: any){
    const workbook = new ExcelJs.Workbook();
    workbook.addWorksheet('Propriétaire');
    const worksheet = workbook.getWorksheet('Propriétaire')

    const headers = ['Destinataire', 'Pays', 'Statut', 'Expéditeur'];

    //Header
    let row = worksheet.getRow(3);
    let column = 1;
    headers.forEach(header => {
        row.getCell(column).value = header;
        column ++;
    });

    const maxColumnWidth = 50;


    //Data
    let rowId = 4;
    messages.forEach((message: any) => {
      const data = [formatPhone(message.receiver), message.country, status(message.status).label, message.sender];
      row = worksheet.getRow(rowId);
      let column = 1;
      for (const col of data) {
        row.getCell(column).value = col;
        column ++;
      }
      rowId++;
    });

    worksheet.autoFilter = {
        from: {row: 3, column: 1},
        to: {row: rowId, column: headers.length}
    }

    worksheet.columns.forEach((column: any) => {
        if(column.values) {
            const lengths = column.values.map((v: any) => v?.toString().length);
            const maxLength =  Math.max(...lengths.filter((v: any) => typeof v === 'number')) + 2;
            column.width = maxLength < maxColumnWidth ? maxLength : maxColumnWidth;
        }
    });

    worksheet.mergeCells("A1:C1");

    row = worksheet.getRow(1);
    row.getCell(1).value = 'Log Messages';
    row.getCell(1).font = { size: 16, bold: true };

    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
        const blob = new Blob([buffer], { type: "application/xlsx"});
        saveAs(blob, 'messages.xlsx');
    })
  }

  const MultilineText = (props) => {
    const record = useRecordContext(props);
    const line = record?.text?.split("\n");
    if (line?.length > 1) {
      return (
        <div style={{marginLeft: "20px"}}>
          {line.map((l, index) => {
            return <p key={index}>{l}</p>
          })}
        </div>
      )
    }
    return <p>{record?.text}</p>;
  }

  const TextMagicStatus = (props) => {
    const record = useRecordContext(props);
    console.log(record)
    if(record?.textMagicId == undefined){
      return <Alert severity="error">L'envoi SMS n'a pas encore été traité par le service. Cela peut prendre quelques minutes après l'envoi de la notification. Si le problème persiste, veuillez réessayer d'envoyer le message plus tard</Alert>
    }
    return <></>
  }

  return (
    <Show>
        <div style={{margin: '15px'}}>
            <TextMagicStatus />
            <h1>Message</h1>
            <MultilineText />
            <h1>Statut des messages envoyés</h1>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{paddingBottom: '5px'}}>
                <Chip label={`${nbDelivred} messages délivrés`} color="success" />
                {nbInProgress <= 0 ? <></> : <Chip label={`${nbInProgress} messages en cours de traitement`} color="info" style={{marginLeft: "10px"}}/>}
                {nbNotReceived <= 0 ? <></> : <Chip label={`${nbNotReceived} messages non réceptionnés`} color="error" style={{marginLeft: "10px"}}/>}
                {nbRejected <= 0 ? <></> : <Chip label={`${nbRejected} messages rejetés`} color="warning" style={{marginLeft: "10px"}}/>}
              </div>
              <div style={{display: 'flex'}}>
                <FormControlLabel control={<Checkbox onChange={() => {setHideDelivered(!hideDelivered)}} checked={hideDelivered} />} label="Cacher les messages délivrés" />
                <Button onClick={refreshMessages} startIcon={<RefreshOutlined />}>Rafraîchir</Button>
                <Button variant="text" onClick={() => {exporter(messages)}} startIcon={<ImportExportOutlined />}>Exporter</Button>
              </div>
            </div>
            <div>
              <DataGrid
                rows={filterRows(messages)}
                style={{ height: '100%', minHeight: '300px', width: '100%'}}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
            </div>
        </div>
    </Show>
  );
}