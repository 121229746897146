import React from 'react';
import {Create, required, SimpleForm, TextInput} from 'react-admin';

export default function LocalityCreate(props: any) {
  return (
    <Create title="createLocality" {...props}>
      <SimpleForm>
          <TextInput source="code" validate={[required()]}/>
          <TextInput source="locality" validate={[required()]}/>
      </SimpleForm>
    </Create>
  );
}
