import React from 'react';
import { Login, LoginForm, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { isDransgrid } from '../../raUtils/httpClient';

const s: { [key: string]: React.CSSProperties } = {
  signupContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    marginBottom: '1em'
  },
};

export default function CustomLoginPage(props: any) {
  const t = useTranslate();

  const backgroundImage = isDransgrid() ? 'signup-background.jpg' : 'https://www.liddes.ch/images/banner-interne.jpg';


  return (
    <Login {...props} backgroundImage={backgroundImage} style={{height: 'auto'}}>
        {
          isDransgrid() ?
          <div style={{ margin: 'auto', width: 400, textAlign: 'center' }}>
            <img
              src="dransgrid-logo-black.png"
              style={{ maxWidth: '80%' }}
              alt="Commune de Liddes"
            />
          </div>
          :
          <div style={{ margin: 'auto', maxWidth: 200 }}>
            <img
              src="https://www.liddes.ch/images/logo.png"
              style={{ maxWidth: '100%' }}
              alt="Commune de Liddes"
            />
          </div>

        }
      <div style={{width: 'fit-content', margin: 'auto'}}>
        <LoginForm />
      </div>
      <div style={s.signupContainer}>
        <Link to="/signup">{t('signup.title')}</Link>
      </div>
    </Login>
  );
}
