import { Grid, Link } from '@mui/material';
import React from 'react';
import {
    DateInput,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    CreateButton,
    useTranslate,
    FunctionField,
    required, NumberInput,
} from 'react-admin';

export default function ActorEdit(props: any) {
    const t = useTranslate();

    const phoneTypeChoices = [
        { type: 'mobile', label: t('phoneTypes.mobile') },
        { type: 'primary', label: t('phoneTypes.primary') },
        { type: 'secondary', label: t('phoneTypes.secondary') },
    ];
    return (
        <Edit title="editActor" {...props} mutationMode="pessimistic">
            <SimpleForm>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <TextInput source="id" label="resources.actors.fields.id" variant={"filled"} disabled style={{ width : '100%'}}/>
                    </Grid>
                    <Grid item xs={8} md={2} style={{ marginTop: 20}}>
                        <FunctionField
                            render={(actor: any) => {
                                const query = new URLSearchParams({ filter: JSON.stringify({ actorId: actor.id }) });

                                return <Link href={`/buildings?${query.toString()}`}>{t('showBuildings')}</Link>;
                            }}
                        />
                    </Grid>
                    <Grid item xs={8} md={4} style={{ marginTop: 20}}>
                        <FunctionField
                            render={(actor: any) => {
                                if(actor.citizen != null) {
                                    return <Link href={`/citizens/${actor.citizen.id}`}>{t('showCitizen')}</Link>;
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <NumberInput source="noActor" label="resources.actors.fields.noActor" variant={"filled"} style={{width: '100%'}} validate={[required()]}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="firstName" label="resources.actors.fields.name" variant={"filled"} style={{width: '100%'}} validate={[required()]}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="filiation" label="resources.actors.fields.filiation" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="filiationAddress" label="resources.actors.fields.filiationAddress" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="address" label="resources.actors.fields.address" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="address2" label="resources.actors.fields.address2" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="locality" label="resources.actors.fields.locality" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="departement" label="resources.actors.fields.departement" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="codeCity" label="resources.actors.fields.codeCity" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="land" label="resources.actors.fields.land" variant={"filled"} style={{width: '100%'}}/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="citizen.email" label="citizenEmail" variant={"filled"} style={{width: '100%'}} disabled/>
                    </Grid>

                    <Grid item xs={8} md={4}>
                        <TextInput source="citizen.phone" label="citizenPhone" variant={"filled"} style={{width: '100%'}} disabled/>
                    </Grid>


                </Grid>
            </SimpleForm>
        </Edit>
    );
}
