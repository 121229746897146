import { TextField } from '@mui/material';
import { useInput, useTranslate } from 'ra-core';
import React, { useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-material-ui';


function PhoneInput(props) {
  const { value, onChange, label, disabled, required } = props;

  const error = {error: false, message: ''};

  const t = useTranslate();

  useEffect(() => {
    //Useful to fix tab index
    const div = document.getElementsByClassName('flag-dropdown')[0];
    div.remove();
  }, []); 

  return (
    <div>
      <ReactPhoneInput
        value={value}
        label={label + (required ? ' *' : '')}
        placeholder='41 79 123 45 67'
        onChange={onChange}
        component={TextField}
        disabled={disabled}
      />
      {error.error ? <p className="css-k4qjio-MuiFormHelperText-root Mui-error" style={{marginTop: "0px"}}>{t(error.message)}</p> : <></>}
    </div>
  );
}

export default (PhoneInput);