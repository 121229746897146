import { Link } from '@mui/material';
import React from 'react';
import {
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
    FunctionField,
    Filter,
    SearchInput,
    ReferenceInput,
    AutocompleteInput, SelectInput,
    ReferenceArrayField, useTranslate,
} from 'react-admin';

import s from './BuildingList.module.css';

import ExcelJs from 'exceljs';
import {saveAs} from 'file-saver';

const NameFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="address" resettable alwaysOn className={s.searchInput} />
    <ReferenceInput
      reference="building_owners"
      source="ownerId"
      alwaysOn
      resettable
    >
      <AutocompleteInput
          source="owner"
          optionText={(owner: any) => {
              if(owner?.id != null) return owner? owner.firstName + " " + owner.lastName : "";
              else return "";
          }}
          optionValue="id"
          label={"buildingOwner"}
          filterToQuery={(search: any) => ({ name: search })}
      />
    </ReferenceInput>
    <ReferenceInput
      reference="actors"
      source="actorId"
      alwaysOn
      resettable
    >
      <AutocompleteInput
          source="owner"
          optionText={(owner: any) => {
              if(owner?.id != null){ return owner? owner.firstName + " " + owner.lastName + " " + owner.noActor : "" ;}
              else{return "";}
          }}
          optionValue="id"
          label={"actor"}
          filterToQuery={(search) => ({ name: search })}
      />
    </ReferenceInput>

    <ReferenceInput
      reference="buildingTypes"
      source="buildingTypeId"
      label={"residenceType.fields"}
    >
      <SelectInput optionText="libelle" label={"residenceType.fields"}/>
    </ReferenceInput>

    <ReferenceInput
      reference="buildingStatus"
      source="buildingStatusId"
      label={"buildingStatus.fields"}
    >
      <SelectInput optionText="libelle" fullWidth={true} label={"buildingStatus.fields"}/>
    </ReferenceInput>

    <SearchInput source="parcel" placeholder={"Parcel"} resettable className={s.searchInput} />
  </Filter>
);

function getHeating (building: any) : string{
    let str : string = "";
    if(building.centralHeating) str += "Central, ";
    if(building.electricHeating) str += "Electrique, ";
    if(building.fuelOilHeating) str += "Mazout, ";
    if(building.woodHeating) str += "Bois, ";
    if(building.otherHeating) str += "Autre, ";
    if(str.length > 2) str = str.slice(0, str.length - 2);
    return str;
}

const exporter = (buildings: any) => {
    const workbook = new ExcelJs.Workbook();
    workbook.addWorksheet('Propriétaire');
    const worksheet = workbook.getWorksheet('Propriétaire')

    const headers = ['Id', 'Type de propriétaire', 'Propriétaire', 'Adresse (propriétaire)', 'Localité (propriétaire)', 'Pays (propriétaire)', 'Adresse', 'Nature',
        'Année', 'Folio', 'Parcelle','Type de résidence', 'Status', 'Remarques', 'Equipements de chauffage',
        'Source d\'énergie principalle', 'Source d\'énregie secondaire', 'Nombre de robinets total', 'Nombre de places de stationnement', 'Mention RA'];

    //Header
    let row = worksheet.getRow(3);
    let column = 1;
    headers.forEach(header => {
        row.getCell(column).value = header;
        column ++;
    });

    const maxColumnWidth = 50;


    //Data
    let rowId = 4;
    buildings.forEach((building: any) => {
        const owners : {owner: string, address: string, locality: string, land: string}[] = [];
        if( building.actors.length > 0) {
            for(const actor of building.actors){
                owners.push({
                  owner: actor.firstName + " " + actor.lastName,
                  address: actor.address,
                  locality: actor.locality,
                  land: actor.land
                })
            }
        } else {
            owners.push({
                owner: building.owner?.firstName + " " + building.owner?.lastName,
                address: building.owner?.address,
                locality: building.owner?.locality,
                land: ""
            })
        }
        const ownerType = (building.actors.length > 0) ? "Acteur(s)" : "Propriétaire";
        const heating = getHeating(building);
        let nbRobinets = 0;
        building.floors?.forEach((floor: any) => {
            nbRobinets += +floor.totalRooms;
        })
        const nbPlaces = building.garages? building.garages.length : 0;
        const raMention = building.raMention? "R1" : "Aucune";

        for (const owner of owners) {
          const data = [building.id, ownerType, owner.owner, owner.address, owner.locality, owner.land, building.address,
              building.type, building.year, building.folio, building.parcel, building.buildingType?.libelle,
              building.status?.libelle, building.comment, heating, building.mainEnergy?.libelle,
              building.secondaryEnergy?.libelle, nbRobinets, nbPlaces, raMention];

          row = worksheet.getRow(rowId);
          let column = 1;
          for (const col of data) {
            row.getCell(column).value = col;
            column ++;
          }
          rowId++;
        }
    });

    worksheet.autoFilter = {
        from: {row: 3, column: 1},
        to: {row: rowId, column: headers.length}
    }

    worksheet.columns.forEach((column: any) => {
        if(column.values) {
            const lengths = column.values.map((v: any) => v?.toString().length);
            const maxLength =  Math.max(...lengths.filter((v: any) => typeof v === 'number')) + 2;
            column.width = maxLength < maxColumnWidth ? maxLength : maxColumnWidth;
        }
    });

    worksheet.mergeCells("A1:C1");

    row = worksheet.getRow(1);
    row.getCell(1).value = 'Export des propriétés';
    row.getCell(1).font = { size: 16, bold: true };

    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
        const blob = new Blob([buffer], { type: "application/xlsx"});
        saveAs(blob, 'proprietes.xlsx');
    })
}

export default function BuildingList(props: any) {
  return (
    <List {...props} filters={<NameFilter />} exporter={exporter} bulkActionButtons={false} style={{paddingTop: "10px"}}>
      <Datagrid>
        <TextField source="address" />
        <FunctionField
          render={(building: any) =>{
              if(building.owner != null) {
                  return <Link
                      href={`/building_owners/${building.owner.id}`}
                  >{`${building.owner.firstName} ${building.owner.lastName}`}</Link>;
              } else if(building.actors != null){

                  let div = <div>  </div>;

                  let childs = React.Children.toArray(div);

                  for(const actor of building.actors){
                      const link = <Link
                          href={`/actors/${actor.id}`}
                          key={`/actors/${actor.id}`}
                      >{`${actor.firstName} ${actor.lastName}`}</Link>;

                      childs.push(link);
                      childs.push(", ");
                  }
                  childs.pop();

                  return <div>{childs}</div>
              }
            }
          }
          label="buildingOwner"
        />
        <TextField source="type" />
        <TextField source="year" />
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
}
