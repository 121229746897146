import { createTheme } from '@mui/material';
import { defaultTheme } from 'react-admin';

/*const theme = createTheme({
  palette: {
    primary: {
      main: '#b30e18',
    },
  },
});*/
const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: '#b30e18',
      layout: {
        appBar: '#c22c11'
      }
    }
  }
}

export default theme;
