import React, { useCallback, FunctionComponent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import validator from 'validator';
import {
  useTranslate,
  useAuthProvider,
  useNotify,
  useSafeSetState,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import {
    Button,
    CardActions,
    CircularProgress,
    TextField,
    Input as MuiInput,
    InputLabel,
    FormControl,
    Box,
    Chip,
    OutlinedInput,
    Alert
} from '@mui/material';
import { Select, MenuItem, Theme } from '@mui/material';
import { SelectChangeEvent } from "@mui/material/Select";
import {  makeStyles } from '@mui/styles';
import { fetchNotificationTypes, fetchVillages, fetchCommunes, getClientCodeByUrl } from '../../api';
import { isDransgrid, isLiddes } from '../../raUtils/httpClient';
import PhoneInput from '../common/PhoneInput';

interface Props {
  redirectTo?: string;
}

interface FormData {
  firstName: string;
  lastName: string;
  email?: string;
  address: string;
  phone: string;
  village: number;
  notificationTypes?: number[];
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
      form: {
        padding: '0 1em 1em 1em',
      },
      input: {
        marginTop: '1em',
      },
      button: {
        width: '100%',
      },
      icon: {
        marginRight: theme.spacing(1),
      },
    }),
    { name: 'RaLoginForm' }
);

const Input = ({
                 // @ts-ignore
                 meta: { touched, error },
                 // @ts-ignore
                 input: inputProps,
                 ...props
               }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const SignupForm: FunctionComponent<Props> = (props: any) => {

  const [submitError, setSubmitError] = useState<any>({error: false, message: ""});
  const [loading, setLoading] = useSafeSetState(false);
  const [villages, setVillages] = useState<any[]>([]);
  const [filteredVillages, setFilteredVillages] = useState<any[]>([{id: -1, name: "Sélectionner une commune", disabled: true}]);
  const [village, setVillage] = useState<any>('');

  const [communes, setCommunes] = useState<any[]>([]);
  const [commune, setCommune] = useState<any>('');

  const [notificationTypes, setNotificationTypes] = useState<any[]>([]);
  const [selectedNotificationTypes, setSelectedNotificationTypes] = useState<any[]>([]);

  const [phone, setPhone] = useState<any>('');

  const notify = useNotify();


  const useSignup = () => {
    const authProvider = useAuthProvider();

    const signup = async (params: any) => {
        authProvider.signup(params).then((ret: any) => {
            window.location = ret.data.redirect;
            return ret;
        }).catch((err: any) => {
            setSubmitError({error: true, message: err?.response?.data?.error?.message});
        });
    }

    return signup;
  };

  const signup = useSignup();
  const t = useTranslate();
  const classes = useStyles(props);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fetchVillages().then((response) => {
        const responseVillages = response.data;
        setVillages(responseVillages);

        if(isLiddes()){
          const liddesVillages = responseVillages.filter((village) => village.commune.name === "Liddes");
          setFilteredVillages(liddesVillages);
        }

        return;
      }),
      fetchCommunes().then((response) => {
          const responseCommunes = response.data;
          const displayCommunes = responseCommunes.filter((commune) => commune.display);
          setCommunes(displayCommunes);
          if(isLiddes()){
            const liddes = displayCommunes.find((commune) => commune.name === "Liddes");
            setCommune(liddes.id);
          }
          return;
      }),
      fetchNotificationTypes().then((response) => {
        return setNotificationTypes(response.data);
      }),
    ])
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  const handleChangeVillage = (event: SelectChangeEvent) => {
      setVillage(event.target.value);
  };

  function filterVillageByCommuneId(id){
    const villagesFromCommune = villages.filter((village) => village.commune.id === id);
    //order by name
    villagesFromCommune.sort((a, b) => (a.name > b.name) ? 1 : -1);
    setFilteredVillages(villagesFromCommune);
  }

  const handleChangeCommune = (event: SelectChangeEvent) => {
      setCommune(event.target.value);
      filterVillageByCommuneId(event.target.value);
  };

  const validPhone = (phone) => {
    if (!phone || phone === undefined) {
      return false;
    }

    // Does not contain any other character than numbers, spaces and +
    if (!/^[0-9 +]+$/.test(phone)) {
      return false;
    }

    return true;
  };

  const validate = (values: FormData) => {
    const errors: any = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      address: undefined,
      phone: undefined,
      village: undefined,
      commune: undefined,
      notificationTypes: undefined,
    };

    if (!values.firstName) {
      errors.firstName = t('ra.validation.required');
    }

    if (!values.lastName) {
      errors.lastName = t('ra.validation.required');
    }

    if (!values.address) {
      errors.address = t('ra.validation.required');
    }

    if (values.email?.trim() && !validator.isEmail(values.email)) {
      errors.email = t('should be an email');
    }

    if (!validPhone(phone)) {
      errors.phone = t('signup.notValidPhone');
    }

    return errors;
  };

  const submit = (values: any) => {
    setLoading(true);

    const categories = isDransgrid() ? notificationTypes.map((notificationType) => notificationType.id) : selectedNotificationTypes;
    const clientCode = getClientCodeByUrl();

    values = {...values, village: village, notificationPreferences: categories, clientCode: clientCode}
    
    signup({ ...values, phone: "+" + phone.replace(/ /g, '')})
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          notify("Erreur lors de l'inscription", {type: 'warning'});
          notify(
              typeof error === 'string'
                  ? error
                  : typeof error === 'undefined' || !error.message
                      ? 'ra.auth.sign_in_error'
                      : error.message,
              {type: 'warning'},
          );
        });
  };

  const getNotificationType = (id: number) => {
      for (const notificationType of notificationTypes){
          if(notificationType.id == id) return notificationType.label;
      }
      return "pas trouvé";
  }

    const changeNotificationTypes = (event: SelectChangeEvent<typeof selectedNotificationTypes>) => {
        const {
            target: { value },
        } = event;
        setSelectedNotificationTypes(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
        },
    };


    return (
      <>
      <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                  <div className={classes.form}>
                      <div className={classes.input}>
                          <Field
                              required
                              autoFocus
                              id="firstName"
                              name="firstName"
                              component={Input}
                              label={t('signup.firstName')}
                              disabled={loading}
                          />
                      </div>
                      <div className={classes.input}>
                          <Field
                              required
                              id="lastName"
                              name="lastName"
                              component={Input}
                              label={t('signup.lastName')}
                              disabled={loading}
                          />
                      </div>
                      <div className={classes.input}>
                          <Field
                              id="email"
                              name="email"
                              component={Input}
                              type="email"
                              label={t('signup.email')}
                              disabled={loading}
                          />
                      </div>
                      <div className={classes.input}>
                          <PhoneInput value={phone} onChange={setPhone} source="phone" label="Numéro de téléphone" required/>
                      </div>
                      <div className={classes.input}>
                          <FormControl fullWidth required>
                              <InputLabel id="label-commune">Commune</InputLabel>
                              <Select sx={{InputLabelRoot:"#d32f2f"}} id="commune" name="commune" labelId="commune" value={commune} style={{width: '100%'}} onChange={handleChangeCommune}>
                                  {
                                      communes.map((commune) => {
                                          return (<MenuItem value={commune.id} key={commune.id}>{commune.name}</MenuItem>)
                                      })
                                  }
                              </Select>
                          </FormControl>
                      </div>
                      <div className={classes.input}>
                          <FormControl required fullWidth>
                              <InputLabel id="label-village">Village</InputLabel>
                              <Select labelId="village" value={village} style={{width: '100%'}} onChange={handleChangeVillage} label="Village">
                                  {
                                      filteredVillages.map((village) => {
                                          return (<MenuItem value={village.id} key={village.id} disabled={village.disabled}>{village.name}</MenuItem>)
                                      })
                                  }
                              </Select>
                          </FormControl>
                      </div>
                      <div className={classes.input}>
                          <Field
                              required
                              id="address"
                              name="address"
                              component={Input}
                              label={t('signup.address')}
                              disabled={loading}
                          />
                      </div>
                      {
                        isDransgrid() ? <></> :
                        <div className={classes.input}>
                            <FormControl required fullWidth>
                                <InputLabel id="label-categorie">Type de notification</InputLabel>
                                <Select
                                    multiple
                                    variant={"filled"}
                                    value={selectedNotificationTypes}
                                    onChange={changeNotificationTypes}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={getNotificationType(value)} />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {notificationTypes.map((notificationType) => (
                                        <MenuItem
                                            key={notificationType.id}
                                            value={notificationType.id}
                                        >
                                            {notificationType.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                      }

                  </div>
                <CardActions>
                  <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      className={classes.button}
                  >
                    {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
                    {t('signup.title')}
                  </Button>
                </CardActions>
              </form>
          )}
      />
      {
        !submitError.error ? <></> :
        <div style={{position: "absolute", bottom: "20px", left: "50%", transform: "translate(-50%)"}}>
            <Alert severity="error">{submitError.message}</Alert>
        </div>
      }
      </>
  );
};

export default SignupForm;
