import { Grid, Link } from '@mui/material';
import React from 'react';
import {
    DateInput,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SelectInput,
    SimpleFormIterator,
    CreateButton,
    useTranslate, FunctionField,
    required,
} from 'react-admin';

export default function BuildingEdit(props: any) {
    const t = useTranslate();

    const phoneTypeChoices = [
        { type: 'mobile', label: t('phoneTypes.mobile') },
        { type: 'primary', label: t('phoneTypes.primary') },
        { type: 'secondary', label: t('phoneTypes.secondary') },
    ];
    return (
        <Edit title="editBuildingOwner" {...props} mutationMode="pessimistic" redirect={"false"}>
            <SimpleForm>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <TextInput source="id" label="resources.building_owners.fields.id" disabled style={{ width : '100%'}}/>
                    </Grid>
                    <Grid item xs={8} md={4} style={{ marginTop: 20}}>
                        <FunctionField
                            render={(owner: any) => {
                                const query = new URLSearchParams({ filter: JSON.stringify({ ownerId: owner.id }) });

                                return <Link href={`/buildings?${query.toString()}`}>{t('showBuildings')}</Link>;
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ width: '100%' }} spacing={2}>
                    <Grid item xs={8} md={4}>
                        <TextInput source="firstName" label="resources.building_owners.fields.firstName" style={{width:'100%'}}  validate={[required()]}/>
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <TextInput source="lastName" label="resources.building_owners.fields.lastName" style={{width:'100%'}}  validate={[required()]}/>
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <TextInput source="address" label="resources.building_owners.fields.address" style={{width:'100%'}} />
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <TextInput source="filiation" label="resources.building_owners.fields.filiation" style={{width:'100%'}} />
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <ReferenceInput
                            reference="localities"
                            source="locality.id"
                            label="locality"
                            filterToQuery={(search) => ({ locality: search })}
                        >
                            <AutocompleteInput
                                source="locality"
                                optionValue="id"
                                optionText={(choice: any) => {
                                    if(choice != null){
                                        return choice.code + " " + choice.locality;
                                    } else {
                                        return "";
                                    }
                                }}
                                fullWidth={true}
                            />
                        </ReferenceInput>
                    </Grid>

                    <Grid item xs={4} md={3} style={{ marginTop: 20 }}>
                        <CreateButton resource="localities" />
                    </Grid>
                </Grid>
                <ArrayInput source="phones">
                    <SimpleFormIterator>
                        <TextInput source="phoneNumber" label="phoneNumber" style={{width: '50%'}} />
                        <SelectInput
                            source="type"
                            label="phoneType"
                            choices={phoneTypeChoices}
                            optionValue="type"
                            optionText="label"
                            style={{width: '50%'}}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
}
