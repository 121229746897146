import { Chip } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Datagrid,
  DeleteButton,
  List,
  TextField,
  Filter,
  SearchInput,
  EditButton,
  DateField,
  ShowButton,
  TopToolbar,
  FunctionField,
} from 'react-admin';
import { SMSCost, SMSLeft, nbFreeSMS } from '../../raUtils/SMSConst';
import API from '../../api';

const NameFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="name" resettable alwaysOn />
  </Filter>
);

export default function SessionSMSList(props: any) {
  const [annualCount, setAnnualCount] = React.useState(0);
  const [lastYearCount, setLastYearCount] = React.useState(0);

  useEffect(() => {
    API.get(`/sessionSMS/anualCount`).then((response) => {
      setAnnualCount(response.data.data.count);
    });
    API.get(`/sessionSMS/lastYearCount`).then((response) => {
      setLastYearCount(response.data.data.count);
    });
  }, []);
  const ListActions = () => {
    return (
      <TopToolbar>
        <Chip label={SMSLeft(annualCount) + " SMS gratuits restant"} color="success"/>
        <Chip label={SMSCost(annualCount) + " CHF de coût suplémentaire"} color="warning"/>
        <Chip label={SMSCost(lastYearCount) + " CHF de coût suplémentaire l'année dernière"} color="info"/>

      </TopToolbar>
    );
  }
  return (
    <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between'}}>
      <List {...props} filters={<NameFilter />} exporter={false} actions={<ListActions />} sort={{ field: 'startTime', order: 'DESC' }}>
        <Datagrid bulkActionButtons={false}>
          <DateField showTime={true} source="startTime" />
          <FunctionField  
            render={(record: any) => {
              console.log(record);              
              return record.textMagicId ? 
                <Chip label="L'envoi a été / est en cours de traitment par le service" color="success" />
              : <Chip label="L'envoi n'a pas encore été traité par le service" color="warning" />
            }}

            label = "Statut"
          />
          <TextField source="textMagicId" />
          <TextField source="countSMS" />
          <TextField source="sendBy.email"/>
          <ShowButton />
        </Datagrid>
      </List>
    </div>
  );
}
