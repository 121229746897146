import {Checkbox, FormGroup, FormControlLabel} from '@mui/material';

import { useInput } from 'react-admin';



const CheckboxInput = props => {
  const {
    field
  } = useInput(props);

  const { label } = props;

  function handleChange (event: React.ChangeEvent<HTMLInputElement>)  {
    field.onChange(event.target.checked);
  }

  return (
      <FormGroup>
        <FormControlLabel control={<Checkbox {...field} defaultChecked={field.value} onChange={handleChange}/>} label={label} />
      </FormGroup>

  );
};
export default CheckboxInput;